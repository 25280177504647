import { useTranslation } from "react-i18next";
import Stack from "../../../ui/stack/Stack.tsx";
import { useCallback, useMemo } from "react";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";
import { Page } from "../../../components/page/Page.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { useEffectOnce } from "react-use";
import { Title } from "../../../components/title/Title.tsx";
import { useIntegrations } from "../../../queries/integrations/use-integrations.query.ts";
import { ApiIntegrationType } from "../../../types/integrations/integration.types.ts";
import { ListItem } from "../../../components/list-item/ListItem.tsx";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import { SettingsIntegrationGoogleCalendarModal } from "./widgets/SettingsIntegrationGoogleCalendar.tsx";
import LinkButtonTo from "../../../ui/link/LinkButtonTo.tsx";

export default function SettingsIntegrations() {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { data: integrations, isLoading } = useIntegrations();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.integrations.viewed);
  });

  const googleCalendarIntegration = useMemo(() => {
    return integrations?.find(
      (x) => x.integration_type == ApiIntegrationType.google_calendar_service_account
    );
  }, [integrations]);

  const handleManageGoogleCalendar = useCallback(() => {
    modalsService.openModal(SettingsIntegrationGoogleCalendarModal, {
      integration: googleCalendarIntegration,
    });
  }, [googleCalendarIntegration]);

  return (
    <>
      <Page>
        <Page.Header title={t("integrations:settings_integrations_title")} showBack />
        <Page.Content>
          <Stack gap={"xl"} className={"container-sm mx-auto"}>
            <Title
              size={"lg"}
              paddingBottom
              paddingTop
              header={t("integrations:settings_integrations_title")}
              caption={t("integrations:settings_integrations_description")}
            />
            {isLoading ? (
              <Skeleton className={"w-full h-24"} />
            ) : (
              <>
                {" "}
                <ListItem
                  title={t("integrations:google_calendar_title")}
                  caption={
                    googleCalendarIntegration?.is_active
                      ? t("integrations:is_active")
                      : `${t("integrations:google_calendar_description")}`
                  }
                  valueSlot={
                    <LinkButtonTo
                      onClick={() => {
                        handleManageGoogleCalendar();
                      }}
                    >
                      {googleCalendarIntegration?.is_active
                        ? t("integrations:manage")
                        : t("integrations:connect")}
                    </LinkButtonTo>
                  }
                />
              </>
            )}
          </Stack>
        </Page.Content>
      </Page>
    </>
  );
}
