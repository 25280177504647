import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import Stack from "../../../ui/stack/Stack.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { useAppTheme } from "../../../themes/provider.tsx";
import { ReactNode } from "react";
import Box from "../../../ui/box/Box.tsx";

export interface SecondaryMenuItemProps {
  to: string;
  isActive: boolean;
  title: string;
  subtitleSlot?: ReactNode;
  count?: number;
  onClick?: () => void;
  icon?: ReactNode;
}

export const SidebarItem = ({
  to,
  isActive,
  title,
  count,
  onClick,
  icon,
  subtitleSlot,
}: SecondaryMenuItemProps) => {
  const sidebarTheme = useAppTheme("sidebar");

  return (
    <Link
      onClick={onClick}
      to={to}
      className={twMerge(
        sidebarTheme.sidebarItem.base,
        isActive ? sidebarTheme.sidebarItem.active.on : sidebarTheme.sidebarItem.active.off,
        !isActive && count == 0 ? sidebarTheme.sidebarItem.dimmed : undefined
      )}
    >
      <Stack direction="horizontal" gap="xs" className="items-baseline w-full" justify={"between"}>
        <Text
          className={twMerge(
            "inline-flex gap-sm overflow-hidden",
            isActive
              ? sidebarTheme.sidebarItemText.active.on
              : sidebarTheme.sidebarItemText.active.off
          )}
        >
          {icon && icon}
          <Text className={"text-ellipsis overflow-hidden whitespace-nowrap"}>{title}</Text>
        </Text>

        {count != undefined && count > 0 && (
          <Text
            className={
              isActive
                ? sidebarTheme.sidebarItemCount.active.on
                : sidebarTheme.sidebarItemCount.active.off
            }
          >
            {count}
          </Text>
        )}
        {subtitleSlot && <Box className={"flex shrink-0"}>{subtitleSlot}</Box>}
      </Stack>
    </Link>
  );
};
