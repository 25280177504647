import { Page } from "../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/button/Button.tsx";
import { Tabs } from "../../../ui/tabs/Tabs.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import {
  useEmployeeDeleteBackground,
  useEmployeeDetails,
  useEmployeeSendInviteConfirmEmail,
  useEmployeeSetBackground,
} from "../../../queries/employees/use-employees.query.ts";
import { parseInt } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { EmployeeFieldPermissions, PermissionType } from "../../../types/role.types.ts";
import { EmployeePersonalInfo } from "./components/EmployeePersonalInfo.tsx";
import { ApiEmployee } from "../../../types/employees/employee.types.ts";
import { EmployeeEditModal } from "../widgets/EmployeeEditModal.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import useToasts from "../../../hooks/use-toasts.hook.tsx";
import { EmployeePageTabOverview } from "./components/EmployeePageTabOverview.tsx";
import { EmployeePositionHistory } from "./components/EmployeePositionHistory.tsx";
import { EmployeeDocuments } from "./components/EmployeeDocuments.tsx";
import { GradientContainer } from "../../../components/gradient-container/GradientContainer.tsx";
import { EmployeeNotes } from "./components/EmployeeNotes.tsx";
import usePermissions from "../../../hooks/use-permissions.hook.ts";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import { ApiFilePathTypes } from "../../../types/file.types.ts";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { ApiFile } from "../../../types/common.types.ts";
import { ImageUploadModal } from "../../../components/avatar-upload/ImageUploadModal.tsx";
import { EmployeeDismissalModal } from "./widgets/EmployeeDismissalModal.tsx";

export function EmployeePage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: employee } = useEmployeeDetails(id ? parseInt(id, 10) : undefined);
  const employeeSendInviteConfirmEmail = useEmployeeSendInviteConfirmEmail();
  const employeeSetBackground = useEmployeeSetBackground();
  const employeeDeleteBackground = useEmployeeDeleteBackground();
  const modalsService = useModals();
  const { employee: employeeMe } = useEmployeeStore();
  const { showInfo } = useToasts();
  const { section } = useParams();
  const navigate = useNavigate();

  const { canViewField, canEditField, canViewAnyOfFields, canEditAnyOfFields } = usePermissions();

  useEffect(() => {
    let tab = "";
    if (section == "positions") tab = "Positions";
    else if (section == "documents") tab = "Documents";
    else if (section == "notes") tab = "Notes";
    else tab = "Overview";

    analyticsService.trackEvent(analyticEvents.employees.viewedEmployee, {
      [analyticProperties.id]: id,
      [analyticProperties.source]: "Page",
      [analyticProperties.tab]: tab,
    });
  }, [section]);

  const canAddEmployee: boolean = useMemo(() => {
    return employeeMe != undefined && employeeMe.permissions.add_employees == PermissionType.edit;
  }, [employeeMe]);

  const canDismissEmployee: boolean = useMemo(() => {
    return (
      employeeMe != undefined && employeeMe.permissions.dismiss_employees == PermissionType.edit
    );
  }, [employeeMe]);

  const canEditEmployee: boolean = useMemo(() => {
    return canEditAnyOfFields(employee, [
      EmployeeFieldPermissions.employee_name,
      EmployeeFieldPermissions.employee_email,
      EmployeeFieldPermissions.employee_date_birth,
      EmployeeFieldPermissions.employee_gender,
      EmployeeFieldPermissions.employee_hired_on,
      EmployeeFieldPermissions.employee_phone_personal,
      EmployeeFieldPermissions.employee_phone,
      EmployeeFieldPermissions.employee_email_personal,

      EmployeeFieldPermissions.employee_number,
      EmployeeFieldPermissions.employee_address_address_line_1,
      EmployeeFieldPermissions.employee_address_address_line_2,
      EmployeeFieldPermissions.employee_address_country,
      EmployeeFieldPermissions.employee_address_state,
      EmployeeFieldPermissions.employee_address_city,
      EmployeeFieldPermissions.employee_address_zip_code,
    ]);
  }, [employee]);

  const canViewJobInfo = useMemo(() => {
    return canViewAnyOfFields(employee, [
      EmployeeFieldPermissions.employee_position_job_title,
      EmployeeFieldPermissions.employee_hired_on,
      EmployeeFieldPermissions.employee_position_division,
      EmployeeFieldPermissions.employee_position_department,
      EmployeeFieldPermissions.employee_position_reporting_to,
      EmployeeFieldPermissions.employee_position_legal_entity,
      EmployeeFieldPermissions.employee_position_location,
      EmployeeFieldPermissions.employee_position_comment,
    ]);
  }, [employee]);

  const handleEditEmployee = useCallback(
    (employee: ApiEmployee) => {
      modalsService.openModal(EmployeeEditModal, {
        employee: employee,
      });
    },
    [employee]
  );

  const handleDismissEmployee = useCallback(
    (employee: ApiEmployee) => {
      modalsService.openModal(EmployeeDismissalModal, {
        employee: employee,
        employeePosition: {
          id: 0,
          date_effective_from: new Date(),
        },
      });
    },
    [employee]
  );

  const handleSendInvite = useCallback(
    async (employee: ApiEmployee) => {
      const success = await employeeSendInviteConfirmEmail.mutateAsync(employee);

      if (success) {
        showInfo(
          t("core:new_employee_invitation_sent", {
            email: employee.email,
          })
        );
      }
    },
    [employee]
  );

  const handleEditBackground = useCallback(() => {
    if (!employee) return;

    modalsService.openModal(ImageUploadModal, {
      modalTitle: t("common:background"),
      path: ApiFilePathTypes.employee,
      onSave: async (file: ApiFile) => {
        try {
          await employeeSetBackground.mutateAsync({
            id: employee.id,
            fileId: file.id,
          });

          analyticsService.trackEvent(analyticEvents.employees.edited, {
            [analyticProperties.id]: employee.id,
            [analyticProperties.actionType]: "Background Updated",
          });
          return true;
        } catch {
          return false;
        }
      },
    });
  }, [employee]);

  const handleDeleteBackground = async () => {
    if (!employee) return;

    try {
      await employeeDeleteBackground.mutateAsync(employee.id);

      analyticsService.trackEvent(analyticEvents.employees.edited, {
        [analyticProperties.actionType]: "Background Deleted",
      });
    } catch {
      return;
    }
  };

  const renderCurrentSection = () => {
    if (!employee) return null;

    if (section == "positions") return <EmployeePositionHistory employee={employee} />;
    if (section == "documents") return <EmployeeDocuments employee={employee} />;
    if (section == "notes") return <EmployeeNotes employee={employee} />;

    return <EmployeePageTabOverview employee={employee} />;
  };

  return (
    <Page className={"bg-light/80"}>
      <div className="relative h-56">
        {employee && (
          <>
            {employee.background_url ? (
              <img
                src={employee.background_url}
                alt={t("common:background")}
                className="w-full h-full object-cover absolute inset-0"
              />
            ) : (
              employee.color_palette &&
              employee.color_palette.length >= 2 && (
                <GradientContainer color_palette={employee.color_palette} />
              )
            )}
            <div className="w-full h-full absolute inset-0 bg-gradient-to-b from-white/60 to-transparent" />
          </>
        )}
        <Stack className="relative z-10 h-full">
          <Page.Header
            title={t("core:profile")}
            showBack={(employeeMe && location.pathname !== `/employees/${employeeMe.id}`) || false}
          >
            <Stack direction={"horizontal"} gap={"sm"}>
              {canEditEmployee && employee && (
                <Button onClick={() => handleEditEmployee(employee)}>{t("common:edit")}</Button>
              )}
              {(canAddEmployee ||
                canDismissEmployee ||
                canEditField(employee, EmployeeFieldPermissions.employee_background)) && (
                <DropdownMenu
                  trigger={
                    <Button>
                      <FontAwesomeIcon icon="fa-light fa-ellipsis" />
                    </Button>
                  }
                >
                  {employee && canAddEmployee && (
                    <Button
                      onClick={() => handleSendInvite(employee)}
                      leftIcon={<FontAwesomeIcon icon="fa-light fa-envelope" />}
                      variant={"menu"}
                    >
                      {t("core:resend_invitation")}
                    </Button>
                  )}
                  {canEditField(employee, EmployeeFieldPermissions.employee_background) && (
                    <Button
                      variant={"menu"}
                      onClick={handleEditBackground}
                      leftIcon={<FontAwesomeIcon icon="fa-light fa-image" />}
                    >
                      {t("core:change_background")}
                    </Button>
                  )}
                  {canEditField(employee, EmployeeFieldPermissions.employee_background) &&
                    employee?.background_url && (
                      <Button
                        variant={"menuDanger"}
                        onClick={handleDeleteBackground}
                        leftIcon={<FontAwesomeIcon icon="fa-light fa-trash" />}
                      >
                        {t("core:remove_background")}
                      </Button>
                    )}
                  {employee && canDismissEmployee && !employee.date_dismissal && (
                    <>
                      <hr />
                      <Button
                        onClick={() => handleDismissEmployee(employee)}
                        leftIcon={<FontAwesomeIcon icon="fa-light fa-person-through-window" />}
                        variant={"menuDanger"}
                      >
                        {t("core:terminate_employee")}
                      </Button>
                    </>
                  )}
                </DropdownMenu>
              )}
            </Stack>
          </Page.Header>
        </Stack>
      </div>
      <Page.Content className={"flex flex-row gap-6 grow py-0 bg-light"}>
        <div className={"w-88 -mt-40 pb-16 flex-shrink-0"}>
          {employee && <EmployeePersonalInfo employee={employee} />}
        </div>
        <div className={"w-full border-l border-l-brand-light overflow-hidden"}>
          {employee && (
            <>
              <Tabs.List>
                <Tabs.Button
                  title={t("common:overview")}
                  isActive={!section}
                  isStretch
                  onClick={() => {
                    navigate(`/employees/${employee.id}`);
                  }}
                />
                {canViewJobInfo && (
                  <Tabs.Button
                    title={t("core:job_info")}
                    isActive={section == "positions"}
                    isStretch
                    onClick={() => {
                      navigate(`/employees/${employee.id}/positions`);
                    }}
                  />
                )}
                {canViewField(employee, EmployeeFieldPermissions.employee_documents) && (
                  <Tabs.Button
                    title={t("core:documents")}
                    isActive={section == "documents"}
                    isStretch
                    onClick={() => {
                      navigate(`/employees/${employee.id}/documents`);
                    }}
                  />
                )}
                {employeeMe && employeeMe.permissions.see_notes == PermissionType.edit && (
                  <Tabs.Button
                    title={t("core:notes")}
                    isActive={section == "notes"}
                    isStretch
                    onClick={() => {
                      navigate(`/employees/${employee.id}/notes`);
                    }}
                  />
                )}
              </Tabs.List>
              <Stack className={"py-16 px-13"}>{renderCurrentSection()}</Stack>
            </>
          )}
        </div>
      </Page.Content>
    </Page>
  );
}
