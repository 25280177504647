import { Card } from "../../../ui/card/Card.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { Link } from "react-router-dom";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { twMerge } from "tailwind-merge";
import Box from "../../../ui/box/Box.tsx";
import { Title } from "../../../components/title/Title.tsx";
import { ReactNode } from "react";

export interface SettingsCardVariant {
  info: string;
  action: string;
}

export interface SettingsCardProps {
  title: string;
  description?: string;
  icon?: string;
  extra?: string;
  to?: string;
  className?: string;
  variant?: keyof SettingsCardVariant;
  onClick?: () => void;
  optionsSlot?: ReactNode;
}

export function SettingsCard({
  title,
  description,
  extra,
  icon,
  to,
  variant = "info",
  className,
  onClick,
  optionsSlot,
}: SettingsCardProps) {
  return (
    <>
      {to ? (
        <Link
          to={to}
          className={className}
          onClick={(event) => {
            if (onClick) {
              event.stopPropagation();
              onClick();
            }
          }}
        >
          <Card
            className={twMerge(
              "cursor-pointer min-h-48 flex flex-col justify-between items-start",
              variant == "action" ? "shadow-none border-dark/10 bg-dark/5 border h-full" : ""
            )}
            actionable
          >
            <Stack
              gap="sm"
              justify={variant == "action" ? "between" : "center"}
              className={"h-full w-full"}
            >
              {icon && (
                <FontAwesomeIcon icon={`fa-light ${icon}`} className={"text-icon-32 pb-4"} />
              )}
              <Stack>
                <Stack items={"center"} justify={"between"} direction={"horizontal"}>
                  <Title header={title} size={"md"} />
                  {optionsSlot}
                </Stack>
                {description && <Text className="text-secondary">{description}</Text>}
              </Stack>
            </Stack>
            {extra && (
              <Text className="text-primary border-dark border text-sm py-1 px-2 rounded-md">
                {extra}
              </Text>
            )}
          </Card>
        </Link>
      ) : (
        <Box
          className={className}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <Card
            className={twMerge(
              "cursor-pointer min-h-48 flex flex-col justify-between items-start",
              variant == "action" ? "shadow-none border-dark/10 bg-dark/5 border h-full" : ""
            )}
            actionable
          >
            <Stack
              gap="sm"
              justify={variant == "action" ? "between" : "center"}
              className={"h-full"}
            >
              {icon && (
                <FontAwesomeIcon icon={`fa-light ${icon}`} className={"text-icon-32 pb-4"} />
              )}
              <Stack direction={"vertical"}>
                <Title header={title} size={"md"} />
                {description && <Text className="text-secondary">{description}</Text>}
              </Stack>
            </Stack>
            {extra && (
              <Text className="text-primary border-dark border text-sm py-1 px-2 rounded-md">
                {extra}
              </Text>
            )}
          </Card>
        </Box>
      )}
    </>
  );
}
