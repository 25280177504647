import { twMerge } from "tailwind-merge";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";

import Stack from "../stack/Stack.tsx";
import Text from "../typography/Text.tsx";
import { range } from "lodash";
import { PaginationProps } from "./pagination.types.ts";
import { useTheme } from "../themes/provider.tsx";

export default function Pagination({
  showingLabel,
  currentPage,
  totalPages,
  onPageChange,
  className,
}: PaginationProps) {
  const innerWindow: number = 2;
  const outerWindow: number = 1;

  const getPages = () => {
    if (totalPages < innerWindow * 2 - 1) {
      return range(1, totalPages + 1);
    }

    let windowFrom = currentPage - innerWindow;
    let windowTo = currentPage + innerWindow;

    if (windowTo > totalPages) {
      windowFrom -= windowTo - totalPages;
      windowTo = totalPages;
    }

    if (windowFrom < 1) {
      windowTo = windowTo + 1 - windowFrom;
      windowFrom = 1;
      if (windowTo > totalPages) {
        windowTo = totalPages;
      }
    }

    const pages: number[] = [];

    if (windowFrom > innerWindow) {
      pages.push(...range(1, outerWindow + 1 + 1));
      pages.push(0);
    } else {
      pages.push(...range(1, windowTo + 1));
    }

    if (windowTo < totalPages - innerWindow + 1) {
      if (windowFrom > innerWindow) {
        pages.push(...range(windowFrom, windowTo + 1));
      }
      pages.push(0);

      if (outerWindow === 0) {
        pages.push(totalPages);
      } else {
        pages.push(...range(totalPages - 1, totalPages + 1));
      }
    } else if (windowFrom > innerWindow) {
      pages.push(...range(windowFrom, totalPages + 1));
    } else {
      pages.push(...range(windowTo + 1, totalPages + 1));
    }

    return pages;
  };

  const theme = useTheme("pagination");

  return (
    <>
      <Stack justify={"between"} items={"center"} direction={"horizontal"}>
        <Stack direction={"horizontal"}>
          <Text className={"text-secondary"}>{showingLabel}</Text>
        </Stack>
        <nav className={twMerge(theme.base, className)}>
          <button
            className={twMerge(theme.pages.base, theme.pages.left)}
            disabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            <FontAwesomeIcon icon={"fa-light fa-chevron-left"} />
          </button>

          {getPages().map((page) => (
            <button
              className={twMerge(
                theme.pages.base,
                theme.pages.number,
                currentPage == page ? theme.pages.active.on : theme.pages.active.off
              )}
              disabled={page == 0}
              onClick={() => onPageChange(page)}
              key={page}
            >
              {page > 0 ? page : "..."}
            </button>
          ))}

          <button
            className={twMerge(theme.pages.base, theme.pages.right)}
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            <FontAwesomeIcon icon={"fa-light fa-chevron-right"} />
          </button>
        </nav>
      </Stack>
    </>
  );
}
