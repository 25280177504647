import { ReactNode } from "react";
import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";
import { Link } from "react-router-dom";
import Box from "../../ui/box/Box.tsx";
import Avatar from "../../ui/avatar/Avatar.tsx";
import { twMerge } from "tailwind-merge";

export interface ListItemProps {
  avatarUrl?: string;
  emoji?: string;
  preTitle?: string;
  title?: string;
  caption?: string;
  value?: string;

  preTitleSlot?: ReactNode;
  titleSlot?: ReactNode;
  captionSlot?: ReactNode;
  valueSlot?: ReactNode;

  to?: string;

  emphasis?: "top" | "middle";
  behavior?: "card" | "list";
}

export const ListItem = ({
  preTitle,
  preTitleSlot,
  avatarUrl,
  emoji,
  title,
  titleSlot,
  caption,
  captionSlot,
  value,
  valueSlot,
  emphasis = "middle",
  behavior = "list",
  to,
}: ListItemProps) => {
  const listItem = (
    <Stack
      justify={"between"}
      className={twMerge(
        "py-2 w-full  min-h-14",
        behavior == "list" ? "border-b border-dark/10" : undefined
      )}
      items={"center"}
      direction={"horizontal"}
      gap={"xs"}
    >
      <Stack className={"overflow-hidden"} direction={"horizontal"} gap={"smd"} items={"center"}>
        {(avatarUrl || emoji) && (
          <Box className={"self-start pt-0.5"}>
            <Avatar size={"md"} url={avatarUrl} emoji={emoji} acronym={title || preTitle || ""} />
          </Box>
        )}
        <Stack direction={"vertical"}>
          {preTitle && (
            <Text className={emphasis == "top" ? "text-title" : "text-secondary"}>{preTitle}</Text>
          )}
          {title && (
            <Text className={emphasis == "middle" ? "text-title" : "text-secondary"}>{title}</Text>
          )}
          {caption && <Text className={"text-secondary"}>{caption}</Text>}
          {preTitleSlot && preTitleSlot}
          {titleSlot && titleSlot}
          {captionSlot && captionSlot}
        </Stack>
      </Stack>
      {value && <Text className={"text-default"}>{value}</Text>}
      {valueSlot && valueSlot}
    </Stack>
  );

  return (
    <>
      {to ? (
        <Link to={to} target="_blank">
          {listItem}
        </Link>
      ) : (
        listItem
      )}
    </>
  );
};
