import React, { ComponentProps, forwardRef } from "react";

export interface BoxProps extends ComponentProps<"div"> {
  children?: React.ReactNode;
}

export const Box = forwardRef<HTMLDivElement, BoxProps>(function Box(
  { children, ...props }: BoxProps,
  ref
) {
  return (
    <>
      <div ref={ref} {...props}>
        {children}
      </div>
    </>
  );
});

export default Box;
