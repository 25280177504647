import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException, ApiPassException } from "../../types/api.types.ts";
import { apiRole } from "../../api/role.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { ApiRoleSavePayload, RoleAssignToConditionType } from "../../types/role.types.ts";
import { ApiEmployeeShort } from "../../types/employees/employee.types.ts";

const rolesKeys = createQueryKeys("roles", {
  list: null,
  detail: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (!id) throw new ApiPassException("Role id is required");

      const result = await apiRole.getById(id);

      if (result.error) throw new ApiErrorException(result.error);

      result.data?.assign_to_list.forEach((rule) => {
        // in RoleAssignToConditionType.employee we have ApEmployeeShort model, and we need to convert it to SelectItem
        if (rule.condition_type === RoleAssignToConditionType.employee) {
          rule.condition_entities = rule.condition_entities.map((entity) => {
            if (entity as unknown as ApiEmployeeShort) {
              const employee = entity as unknown as ApiEmployeeShort;
              const title = `${employee.first_name} ${employee.last_name}`;

              return {
                id: entity.id,
                title,
              };
            } else {
              return entity;
            }
          });
        }
      });

      return result.data;
    },
  }),
});

export const useRoleById = (id: number | undefined) => {
  return useQuery({
    ...rolesKeys.detail(id),
    enabled: !!id,
  });
};

export const useRoles = () => {
  return useQuery({
    ...rolesKeys.list,
    queryFn: async () => {
      const result = await apiRole.getAll();

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
  });
};

export interface RolesSaveParams {
  id: number;
  payload: ApiRoleSavePayload;
}

export const useRolesSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function ({ id, payload }: RolesSaveParams) {
      const result = id == 0 ? await apiRole.create(payload) : await apiRole.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (role) => {
      queryClient.setQueryData(rolesKeys.detail(role.id).queryKey, role);
      queryClient.invalidateQueries({
        queryKey: rolesKeys.list.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useRolesDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function (id: number) {
      const { success, error } = await apiRole.delete(id);

      if (error || !success) throw new ApiErrorException(error);

      return id;
    },
    onSuccess: (id) => {
      queryClient.setQueryData(rolesKeys.detail(id).queryKey, undefined);
      queryClient.invalidateQueries(rolesKeys.list);
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
