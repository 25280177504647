import { ApiFilterBase } from "../filter.types.ts";
import { ApiEmployeeShort } from "../employees/employee.types.ts";
import { ApiApplicantCV } from "./applicant.cv.types.ts";
import { ApiFile, ApiPartialDate } from "../common.types.ts";
import { ApiJobShort } from "./job.types.ts";
import { ApiPipelineShort } from "./pipeline.types.ts";
import { ApiAction } from "../action.types.ts";
import { ApiCandidateDisqualificationReason } from "./disqualification-reason.types.ts";
import { ApiLabel } from "./label.types.ts";
import { SourceTypes } from "./source.types.ts";
import { ApiPipelineStageShort } from "./stages.types.ts";
import { ApiApplicantDataProtection } from "../data-protection.types.ts";

export interface ApiApplicantContacts {
  phone?: string;
  email?: string;
  skype_username?: string;
  telegram_username?: string;
}

export interface ApiApplicantPosition {
  position?: string;
  company?: string;
}

export interface ApiApplicantSource {
  id: number;
  source_type: SourceTypes;
}

export interface ApiApplicantBase {
  id: number;
  first_name: string;
  last_name?: string;
  middle_name?: string;
  photo_url?: string;
}

export interface ApiApplicantShort extends ApiApplicantBase {
  location?: string;
  contacts: ApiApplicantContacts;
  current_position: ApiApplicantPosition;
  salary?: string;
  birth_date?: ApiPartialDate;
  photo_url?: string;
  resume?: string;
  source_id: number;
  stage_is_overdue: boolean;

  last_action?: ApiAction;
  last_candidate?: ApiCandidateShort;

  datetime_created_at: Date;

  data_protection?: ApiApplicantDataProtection[];
}

export interface ApiApplicantSavePayload {
  first_name: string;
  last_name?: string;
  middle_name?: string;
  location?: string;
  contacts: ApiApplicantContacts;
  current_position: ApiApplicantPosition;
  salary?: string;
  birth_date?: ApiPartialDate;
  source_id?: number;
  resume?: string;
}

export interface ApiApplicantCreatePayload {
  new_applicant: ApiApplicantSavePayload;
  file_ids: number[];
}

export interface ApiApplicantFilter extends ApiFilterBase {
  location?: string;
  text?: string;
  position?: string;
  date_created_from?: Date;
  date_created_to?: Date;
  source_ids?: number[];
  pipeline_stage_ids?: number[];
  job_ids?: number[];
  disqualification_reason_ids?: number[];
  reserved?: boolean;
  disqualified?: boolean;
  applicant_ids?: number[];
  date_last_candidate_action_from?: Date;
  date_last_candidate_action_to?: Date;
  label_ids?: number[];
}

export interface ApiApplicantParsed {
  applicant: ApiApplicantShort;
  files: ApiFile[];
}

export interface ApiApplicant extends ApiApplicantShort {
  author: ApiEmployeeShort;
  cvs: ApiApplicantCV[];
  labels: ApiLabel[];
  source: ApiApplicantSource;

  similar_applicants: ApiApplicantShort[];
}

export interface ApiCandidateShort {
  id: number;
}

export interface ApiCandidate {
  id: number;
  datetime_created_at: string;
  job: ApiJobShort;
  pipeline: ApiPipelineShort;
  pipeline_stage: ApiPipelineStageShort;
  reserved?: boolean | false;
  disqualification_reason?: ApiCandidateDisqualificationReason | null;

  datetime_stage_deadline?: Date;
  stage_is_overdue: boolean;
  stage_time_limit: number;
  last_action?: ApiAction;
}

export const ApplicantShortEmpty: ApiApplicantShort = {
  id: 0,
  first_name: "",
  last_name: "",
  middle_name: "",
  location: "",
  contacts: {},
  current_position: {},
  salary: "",
  birth_date: undefined,
  photo_url: "",
  resume: "",
  source_id: 0,
  stage_is_overdue: false,
  datetime_created_at: new Date(),
};
