import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";

export interface AvatarSizes extends Pick<ThemeSizes, "xs" | "sm" | "md" | "xl" | "2xl" | "4xl"> {
  [key: string]: string;
}

export interface AvatarProps {
  url?: string | null;
  size?: keyof AvatarSizes;
  onLoad?: () => void;
  emoji?: string;
  acronym?: string;
  variant?: keyof AvatarVariant;
}

export interface AvatarVariant {
  default: string;
  opaque: string;
  plain: string;
}

export interface UIKitAvatarTheme extends UIKitThemeBase {
  emoji: string;
  acronym: string;
  variants: {
    size: AvatarSizes;
    emojiSize: AvatarSizes;
    acronymSize: AvatarSizes;
    variant: AvatarVariant;
  };
}

export const avatarTheme: UIKitAvatarTheme = {
  base: "flex-shrink-0 overflow-hidden flex items-center justify-center",
  emoji: "text-default",
  acronym: "text-dark/50 ",
  variants: {
    size: {
      xs: "w-4 h-4 rounded-full", // 16px
      sm: "w-5 h-5 rounded-md", // 20px
      md: "w-8 h-8 rounded-lg", // 32px
      xl: "w-16 h-16 rounded-lg", // 64px, Empty state
      "2xl": "w-40 h-40 rounded-lg ",
      "4xl": "w-56 h-56 rounded-2xl ",
      // "2xl": "w-24 h-24 rounded-2xl shadow-sm ",
      // "4xl": "w-40 h-40 rounded-2xl shadow-md ",
    },
    acronymSize: {
      xs: "text-[8px] font-semibold",
      sm: "text-[10px] font-semibold",
      md: "text-[14px] font-semibold",
      xl: "text-[24px] font-semibold",
      "2xl": "text-[74px] font-semibold",
      "4xl": "text-[128px] font-semibold", //?
    },
    emojiSize: {
      xs: "text-[12px]",
      sm: "text-[14px]",
      md: "text-[22px]",
      xl: "text-[40px]",
      "2xl": "text-[120px]",
      "4xl": "text-[200px]", // ?
    },
    variant: {
      default: "bg-dark/5",
      opaque: "bg-light border border-dark/5 ",
      plain: "",
    },
  },
};
