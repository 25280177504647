import Stack from "../../../ui/stack/Stack.tsx";
import { EventContentArg } from "@fullcalendar/core";
import { ApiEvent, ApiEventAttendeeResponseStatuses } from "../../../types/event.types.ts";
import { useMemo } from "react";
import { EventPreviewModal } from "../../../components/event/EventPreviewModal.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { twMerge } from "tailwind-merge";
import useFormatter from "../../../hooks/use-formatter.hook.ts";

export interface EventInCalendarProps {
  eventInfo: EventContentArg;
  viewAsEmployeeId?: number;
}

export function EventInCalendar({ eventInfo, viewAsEmployeeId }: EventInCalendarProps) {
  const modalsService = useModals();
  const { renderTime } = useFormatter();
  const myEvent = useMemo(() => {
    return eventInfo.event.extendedProps as ApiEvent;
  }, [eventInfo]);

  const isShort = useMemo(() => {
    return (myEvent.datetime_end.getTime() - myEvent.datetime_start.getTime()) / 1000 / 60 <= 30;
  }, [myEvent]);

  const handleClick = () => {
    modalsService.openModal(EventPreviewModal, {
      event: myEvent,
    });
  };

  const isPassedEvent = useMemo(() => {
    return myEvent.datetime_end.getTime() < Date.now();
  }, [myEvent]);

  const viewerAsAttendee = useMemo(() => {
    return (
      viewAsEmployeeId &&
      myEvent.attendees.find((attendee) => attendee.employee?.id === viewAsEmployeeId)
    );
  }, [viewAsEmployeeId, myEvent]);

  const eventClasses = useMemo(() => {
    const classes: string[] = [];
    if (viewerAsAttendee) {
      switch (viewerAsAttendee.response_status) {
        case ApiEventAttendeeResponseStatuses.accepted:
          classes.push("bg-brand-dark text-light");
          break;
        case ApiEventAttendeeResponseStatuses.needs_action:
          classes.push("border-brand-dark border text-brand-dark bg-brand-light");
          break;
      }
    }
    if (isPassedEvent) {
      classes.push("opacity-50");
    }
    return classes;
  }, [viewerAsAttendee, myEvent]);

  return (
    <Stack
      onClick={handleClick}
      direction={"vertical"}
      className={twMerge("flex h-full px-2 rounded-md cursor-pointer", eventClasses)}
    >
      <div
        className={twMerge(
          "text-sm ",
          isShort ? "whitespace-nowrap text-ellipsis overflow-hidden" : "py-1"
        )}
      >
        <strong>{eventInfo.event.title}</strong>
        {!eventInfo.event.allDay && (
          <span>{eventInfo.event.start ? ", " + renderTime(eventInfo.event.start) : ""}</span>
        )}
      </div>
      {myEvent && !isShort && (
        <div className={"text-sm text-opacity-60"}>{myEvent.description}</div>
      )}
    </Stack>
  );
}
