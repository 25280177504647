import { ApiDepartmentShort } from "../department.types.ts";
import { ApiDivisionShort } from "../division.types.ts";
import { ApiLevel, ApiPositionShort } from "../position.types.ts";
import { ApiEmployee } from "./employee.types.ts";
import { ApiLocation } from "../location.types.ts";
import { ApiLegalEntity } from "../legal-entity.types.ts";
import { ApiEmployeeDismissalReason } from "./employee-dismissal-reason.types.ts";

export interface ApiEmployeeAssignPositionPayload {
  date_effective_from?: Date;
  division_id?: number;
  department_id?: number;
  position_id?: number;
  position_level_id?: number;
  reporting_to_id?: number;
  legal_entity_id?: number;
  location_id?: number;
  dismissal_reason_id?: number;
  comment?: string;
}

export interface ApiEmployeePosition {
  id: number;
  date_effective_from: Date;
  department?: ApiDepartmentShort;
  division?: ApiDivisionShort;
  position?: ApiPositionShort;
  position_level?: ApiLevel;
  dismissal_reason?: ApiEmployeeDismissalReason;
  manager?: ApiEmployee;
  location?: ApiLocation;
  legal_entity?: ApiLegalEntity;
  comment?: string;
}

export const getEmployeePositionTitle = (employee: ApiEmployee): string => {
  const level = employee.employee_position.position_level
    ? employee.employee_position.position_level.title + " "
    : "";
  return level + (employee.employee_position.position?.title || "");
};
