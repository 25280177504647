import { api } from "../axios.ts";
import {
  ApiEmployeeDismissalReason,
  ApiEmployeeDismissalReasonPayload,
} from "../../types/employees/employee-dismissal-reason.types.ts";
import { ApiOperationResult } from "../../types/common.types.ts";

export const apiEmployeesDismissalReason = {
  getAll: () => {
    return api.get<ApiEmployeeDismissalReason[]>("/api/v1/employees/dismissal/reason");
  },
  create: (payload: ApiEmployeeDismissalReasonPayload) => {
    return api.post<ApiEmployeeDismissalReason>("/api/v1/employees/dismissal/reason", payload);
  },
  update: (id: number, payload: ApiEmployeeDismissalReasonPayload) => {
    return api.put<ApiEmployeeDismissalReason>(`/api/v1/employees/dismissal/reason/${id}`, payload);
  },
  delete: (id: number) => {
    return api.delete<ApiOperationResult>(`/api/v1/employees/dismissal/reason/${id}`);
  },
};
