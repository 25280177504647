import { format, formatISO, parse, parseISO } from "date-fns";

// https://skailer.atlassian.net/wiki/spaces/Skailer/pages/6324246

export const handleDatesResponse = (data: unknown) => {
  // if (isIsoDateString(data)) return parseISO(data);
  if (data === null || data === undefined || typeof data !== "object") return data;

  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (key.startsWith("date_") && val) data[key] = parse(val, "yyyy-MM-dd", new Date());
    // @ts-expect-error this is a hack to make the type checker happy (2024-04-15T21:39:48.777584)
    else if (key.startsWith("datetime_") && val) data[key] = parseISO(val);
    else if (key.startsWith("time_") && val) {
      // @ts-expect-error this is a hack to make the type checker happy (2024-04-15T21:39:48.777584)
      data[key] = parse(val, "HH:mm:ss", new Date());
    } else if (typeof val === "object") handleDatesResponse(val);
  }

  return data;
};

export const handleDatesRequest = (data: unknown) => {
  // if (isIsoDateString(data)) return parseISO(data);

  if (data === null || data === undefined) return data;
  //instanceof

  for (const [key, val] of Object.entries(data)) {
    if (val instanceof Date) {
      // @ts-expect-error this is a hack to make the type checker happy
      if (key.startsWith("date_")) data[key] = format(val, "yyyy-MM-dd");
      // @ts-expect-error this is a hack to make the type checker happy
      else if (key.startsWith("datetime_")) data[key] = formatISO(val);
      // @ts-expect-error this is a hack to make the type checker happy
      else if (key.startsWith("time_")) data[key] = format(val, "HH:mm:ss");
    } else if (typeof val === "object") handleDatesRequest(val);
  }

  return data;
};
