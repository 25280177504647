import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Modal from "../../ui/modal/modal";
import Button from "../../ui/button/Button";
import { Input } from "../../ui/input/Input.tsx";
import { ModalProps } from "../../ui/modal/modal.types.ts";
import i18n from "../../i18n.ts";
import {
  ApiEventAttendee,
  ApiEventAttendeeResponseStatuses,
  ApiEventEdit,
  ApiEventSavePayload,
  ApiEventTypes,
  ApiEventVisibility,
  getDescriptionForAttendee,
  getDurations,
} from "../../types/event.types.ts";
import { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import getTimezones, { DefaultTimezone } from "../../services/timezone-service.ts";
import useModals from "../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { ApiActionPayload, ApiActionVisibilities } from "../../types/action.types.ts";
import { useEventsSave } from "../../queries/use-events.query.ts";
import {
  useCandidateActionCreate,
  useCandidateActionUpdate,
} from "../../queries/recruit/use-candidate-actions.query.ts";
import { Title } from "../title/Title.tsx";
import Box from "../../ui/box/Box.tsx";
import Datepicker from "../../ui/datepicker/Datepicker.tsx";
import { useEffect, useMemo, useState } from "react";
import { Time } from "../../types/common.types.ts";
import { getTimeId } from "../../helpers/dates.helper.ts";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import Select from "../../ui/select/Select.tsx";
import { useCalendars } from "../../queries/use-calendars.query.ts";
import { Textarea } from "../../ui/textarea/Textarea.tsx";
import { UserListItem } from "../user-list/UserListItem.tsx";
import useEmployeeSearchInline, {
  useEmployeesByIds,
} from "../../queries/employees/use-employees.query.ts";
import DropdownMenu from "../../ui/dropdown-menu/DropdownMenu.tsx";
import Text from "../../ui/typography/Text.tsx";
import { Switch } from "../../ui/switch/Switch.tsx";
import { add, differenceInMinutes, set } from "date-fns";
import { ApiCalendarProviders } from "../../types/calendar.types.ts";

export interface EventInEditModalProps extends ModalProps {
  event: ApiEventEdit;
  eventId?: number;
}

export function EventEditModal({ event, eventId, ...props }: EventInEditModalProps) {
  const { t } = useTranslation();
  const { renderTime } = useFormatter();
  const { renderEmployeeName } = useFormatter();
  const { close } = useModals();
  const { id } = { ...props };

  const EventSchemaObject = yup.object({
    title: yup.string().required(i18n.t("errors:field_required")),
    // start: yup.date().required(i18n.t("errors:field_required")),
    // end: yup.date().required(i18n.t("errors:field_required")),
    // date_event: yup.date().required(i18n.t("errors:field_required")),
    // time_start: yup.date().required(i18n.t("errors:field_required")),
    // time_end: yup.date().required(i18n.t("errors:field_required")),
    // duration_minutes: yup.number().required(i18n.t("errors:field_required")),
    timezone: yup.string(),
    description: yup.string(),
    visibility: yup.mixed<ApiEventVisibility>().oneOf(Object.values(ApiEventVisibility)),
    // guest_ids: yup.array().of(yup.number().required()).required(),
    calendar_id: yup.number().required(i18n.t("errors:field_required")),
    create_conference: yup.boolean(),
    // is_all_day: yup.boolean().required(i18n.t("errors:field_required")),

    // attendees: yup.array().of(
    //   yup.object().shape({
    //     // id: yup.number(),
    //     // title: yup.string().required(i18n.t("errors:field_required")),
    //     // employee
    //   })
    // ),
    //Interview
    // is_invite_candidate: yup.boolean(),
  });

  interface EventSchema extends yup.InferType<typeof EventSchemaObject> {}

  const [dateStart, setDateStart] = useState(event.datetime_start);
  const [dateEnd, setDateEnd] = useState(event.datetime_end);
  const [isAllDayEvent, setIsAllDayEvent] = useState(event.is_all_day);
  const [calendar, setCalendar] = useState(event.calendar);

  const durationInMinutes = useMemo(() => {
    return differenceInMinutes(dateEnd, dateStart);
  }, [dateStart, dateEnd]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EventSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EventSchemaObject),
    defaultValues: {
      title: event.title,
      // start: even,
      // end: dateEnd,
      // date_event: startOfDay(event.datetime_start),
      // time_start: event.datetime_start,
      // duration_minutes: differenceInMinutes(event.datetime_end, event.datetime_start),
      // time_end: event.datetime_end,
      timezone: event.timezone,
      calendar_id: event.calendar.id,
      description: event.description,
      visibility: event.visibility,
      create_conference: event.is_conference,
    },
  });

  const [attendees, setAttendees] = useState<ApiEventAttendee[]>(event.attendees);
  const { data: usersLoaded } = useEmployeesByIds(
    event.attendees.filter((x) => x.employee).map((x) => x.employee?.id || 0)
  );

  useEffect(() => {
    if (usersLoaded && usersLoaded.length > 0) {
      const prevAttendees = attendees;
      for (let i = 0; i < prevAttendees.length; i++) {
        if (prevAttendees[i].employee) {
          const user = usersLoaded.find((x) => x.id == prevAttendees[i].employee?.id);
          if (user) {
            prevAttendees[i].runtime_only_employee = user;
          }
        }
      }
      setAttendees(prevAttendees);
    }
  }, [usersLoaded]);

  const [suggestedAttendees, setSuggestedAttendees] = useState<ApiEventAttendee[]>(
    event.suggestedAttendees || []
  );

  const employeesSearch = useEmployeeSearchInline();
  const eventActionSave = useEventsSave(control);
  const candidateActionCreate = useCandidateActionCreate(control);
  const candidateActionUpdate = useCandidateActionUpdate(control);

  const [isCustomStartTime, setIsCustomStartTime] = useState(false);
  const { data: calendars } = useCalendars();

  useEffect(() => {
    if (calendars && calendars.length > 0 && event.calendar.id == 0) {
      setValue("calendar_id", calendars[0].id);
      setCalendar(calendars[0]);
    }
  }, [calendars, event]);

  const startTimes = useMemo(() => {
    const startTimes: Time[] = [];

    for (let i = 9; i < 20; i++) {
      const date1 = new Date(2000, 1, 1, i, 0);
      const date2 = new Date(2000, 1, 1, i, 30);

      startTimes.push({
        id: getTimeId(date1),
        title: renderTime(date1),
        date: date1,
      });

      startTimes.push({
        id: getTimeId(date2),
        title: renderTime(date2),
        date: date2,
      });
    }
    return startTimes;
  }, []);

  const startTimesFull = useMemo(() => {
    const startTimes: Time[] = [];

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        const date = new Date(2000, 1, 1, i, j);

        startTimes.push({
          id: getTimeId(date),
          title: renderTime(date),
          date: date,
        });
      }
    }

    return startTimes;
  }, []);

  const privacyOptions = useMemo(() => {
    return [
      { id: "public", title: t("calendar:event_visibility_public") },
      { id: "private", title: t("calendar:event_visibility_private") },
    ];
  }, []);

  const onSubmit = handleSubmit((schema) => {
    const eventPayload: ApiEventSavePayload = {
      type: event.type,
      title: schema.title,
      timezone: schema.timezone || DefaultTimezone,
      description: schema.description || "",
      datetime_start: dateStart,
      datetime_end: dateEnd,
      calendar_id: schema.calendar_id,
      visibility: schema.visibility || ApiEventVisibility.public,
      attendees: attendees.map((x) => ({
        employee_id: x.employee?.id,
        email: x.email,
        is_organizer: x.is_organizer,
      })),
      is_all_day: isAllDayEvent,
      is_conference: schema.create_conference || false,
    };

    if (event.type == ApiEventTypes.interview && event.interview && !eventId) {
      eventPayload.interview = {
        candidate_id: event.interview.candidate_id,
        hide_salary: event.interview.hide_salary,
      };
      const payload: ApiActionPayload = {
        event: eventPayload,
        visibility: ApiActionVisibilities.public,
      };

      candidateActionCreate.mutate(
        { id: event.interview.candidate_id, payload: payload },
        {
          onSuccess: () => {
            analyticsService.trackEvent(analyticEvents.events.created, {
              [analyticProperties.actionType]: "Candidate",
            });
            close(id);
          },
        }
      );
    } else {
      eventActionSave.mutateAsync(
        { id: eventId, payload: eventPayload },
        {
          onSuccess() {
            if (eventId) {
              analyticsService.trackEvent(analyticEvents.events.edited, {
                [analyticProperties.id]: eventId,
                [analyticProperties.actionType]: "Calendar",
              });
            } else {
              analyticsService.trackEvent(analyticEvents.events.created, {
                [analyticProperties.actionType]: "Calendar",
              });
            }

            close(id);
          },
        }
      );
    }
  });

  const getText2SlotForAttendee = (attendee: ApiEventAttendee) => {
    const texts: string[] = getDescriptionForAttendee(attendee);

    return (
      <Text className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}>
        {texts.join(" · ")}
      </Text>
    );
  };

  const getValueSlotForAttendee = (attendee: ApiEventAttendee) => {
    if (attendee.is_organizer) {
      return null;
    }

    return (
      <DropdownMenu>
        <Stack gap="sm">
          <Button
            variant="menu"
            onClick={() => {
              if (attendee.employee) {
                setAttendees(attendees.filter((x) => x.employee?.id != attendee.employee?.id));
              } else {
                setAttendees(attendees.filter((x) => x.email != attendee.email));
              }
            }}
          >
            {t("calendar:remove_guest")}
          </Button>
        </Stack>
      </DropdownMenu>
    );
  };

  return (
    <Modal
      {...props}
      layout="base"
      size={"md"}
      title={eventId ? t("calendar:event_edit_title") : t("calendar:event_create_title")}
      withCloser
      closeByEscEnabled
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button
            onClick={onSubmit}
            isLoading={
              eventActionSave.isPending ||
              candidateActionCreate.isPending ||
              candidateActionUpdate.isPending
            }
            size={"lg"}
            className={"w-full"}
            type={"submit"}
          >
            {t("common:save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit} className={"flex flex-col flex-grow"}>
        <Input
          label={t("common:title")}
          size={"lg"}
          type="text"
          {...register("title")}
          error={errors.title?.message}
        />
        <Title size={"xs"} header={t("common:date_and_time")} paddingTop paddingBottom />
        <Box className={"grid grid-cols-3 gap-sm"}>
          <Box className={"col-span-2"}>
            <Datepicker
              value={dateStart}
              onChange={(newValue) => {
                if (newValue) {
                  setDateStart(
                    set(dateStart, {
                      year: newValue.getFullYear(),
                      month: newValue.getMonth(),
                      date: newValue.getDate(),
                    })
                  );
                }
              }}
              label={t("common:date")}
              className={"col-span-2"}
            />
          </Box>
          <Box className={"self-center justify-self-end"}>
            <Switch
              checked={isAllDayEvent}
              onChange={(e) => {
                setIsAllDayEvent(e);
              }}
              title={t("calendar:event_all_day")}
            />
          </Box>
        </Box>
        {isAllDayEvent && (
          <Datepicker
            value={dateEnd}
            onChange={(newValue) => {
              if (newValue) {
                setDateEnd(
                  set(dateEnd, {
                    year: newValue.getFullYear(),
                    month: newValue.getMonth(),
                    date: newValue.getDate(),
                  })
                );
              }
            }}
            label={t("common:date_to")}
            className={"col-span-2"}
          />
        )}

        {!isCustomStartTime && (
          <Select
            value={durationInMinutes}
            emptyTitle={t("common:duration")}
            options={getDurations()}
            onChange={(duration) => {
              if (duration) {
                setDateEnd(
                  set(dateStart, {
                    minutes: dateStart.getMinutes() + parseInt(duration as string, 10),
                  })
                );
              }
            }}
          />
        )}
        {!isAllDayEvent && (
          <>
            <Box className={"grid grid-cols-4 gap-x-xs gap-y-sm py-2"}>
              {startTimes.map((time) => (
                <Button
                  variant={
                    !isCustomStartTime && getTimeId(time.date) == getTimeId(dateStart)
                      ? "primary"
                      : "secondary"
                  }
                  size={"sm"}
                  key={time.id}
                  leftIcon={
                    !isCustomStartTime && getTimeId(time.date) == getTimeId(dateStart) ? (
                      <FontAwesomeIcon icon={"fa-light fa-check"} />
                    ) : undefined
                  }
                  onClick={() => {
                    const durationCache = durationInMinutes;
                    setDateStart(
                      set(dateStart, {
                        hours: time.date.getHours(),
                        minutes: time.date.getMinutes(),
                      })
                    );
                    setDateEnd(
                      add(
                        set(dateEnd, {
                          hours: time.date.getHours(),
                          minutes: time.date.getMinutes(),
                        }),
                        { minutes: durationCache }
                      )
                    );
                    setIsCustomStartTime(false);
                  }}
                >
                  {time.title}
                </Button>
              ))}
              <Button
                variant={isCustomStartTime ? "primary" : "secondary"}
                size={"sm"}
                className={"col-span-2"}
                onClick={() => {
                  setIsCustomStartTime(true);
                }}
              >
                {t("common:custom")}
              </Button>
            </Box>
            {isCustomStartTime && (
              <Box className={"grid grid-cols-2 gap-x-xs "}>
                <Select
                  value={getTimeId(dateStart)}
                  emptyTitle={t("common:time")}
                  onChange={(newValue) => {
                    const time = startTimesFull.find((time) => time.id == newValue);
                    if (time) {
                      setDateStart(
                        set(dateStart, {
                          hours: time.date.getHours(),
                          minutes: time.date.getMinutes(),
                        })
                      );
                    }
                  }}
                  options={startTimesFull}
                  searchable
                />

                <Select
                  value={getTimeId(dateEnd)}
                  emptyTitle={t("common:time")}
                  onChange={(newValue) => {
                    const time = startTimesFull.find((time) => time.id == newValue);
                    if (time) {
                      setDateEnd(
                        set(dateEnd, {
                          hours: time.date.getHours(),
                          minutes: time.date.getMinutes(),
                        })
                      );
                    }
                  }}
                  options={startTimesFull}
                  searchable
                />
              </Box>
            )}
          </>
        )}

        <Controller
          control={control}
          name="timezone"
          render={({ field: { value, onChange } }) => (
            <Select
              label={t("common:timezone")}
              options={getTimezones()}
              emptyTitle={t("common:select")}
              searchable
              value={value}
              onChange={onChange}
              error={errors.timezone?.message}
            />
          )}
        />
        <Title size={"xs"} header={t("calendar:guests")} paddingTop paddingBottom />
        <Select
          label={t("calendar:add_guests")}
          emptyTitle={t("common:select")}
          value={null}
          options={[]}
          searchable
          showAvatar={true}
          showCaption={true}
          onSearchOptions={(text) => employeesSearch.search(text)}
          searchOptions={employeesSearch.options}
          loadOptionsOnOpen
          onChange={(e) => {
            const addEmployeeAsGuest = employeesSearch.data?.items.find((x) => x.id == e);

            if (addEmployeeAsGuest) {
              setAttendees([
                ...attendees,
                {
                  employee: addEmployeeAsGuest,
                  runtime_only_employee: addEmployeeAsGuest,
                  response_status: ApiEventAttendeeResponseStatuses.needs_action,
                  is_organizer: false,
                },
              ]);
            }
          }}
          onAddNew={(text) => {
            setAttendees([
              ...attendees,
              {
                email: text,
                response_status: ApiEventAttendeeResponseStatuses.needs_action,
                is_organizer: false,
              },
            ]);
          }}
          addNewPrefix={t("calendar:invite")}
        />
        <Stack className={"py-4"} gap={"md"}>
          {attendees.map((attendee, index) => (
            <UserListItem
              key={`attendee-${index}`}
              primaryText={
                attendee.employee ? renderEmployeeName(attendee.employee) : attendee.email || ""
              }
              text2Slot={getText2SlotForAttendee(attendee)}
              avatarUrl={attendee.employee?.avatar_url}
              valueSlot={getValueSlotForAttendee(attendee)}
            />
          ))}
          {suggestedAttendees.map((attendee, index) => (
            <UserListItem
              key={`suggested-attendee-${index}`}
              primaryText={attendee.email || ""}
              primarySlot={
                <Text
                  className={
                    "text-title overflow-hidden overflow-ellipsis whitespace-nowrap opacity-50"
                  }
                >
                  {attendee.email || ""}
                </Text>
              }
              text2Slot={
                <Text
                  className={
                    "text-title overflow-hidden overflow-ellipsis whitespace-nowrap opacity-50"
                  }
                >
                  {t("calendar:invite")}
                </Text>
              }
              avatarUrl={attendee.employee?.avatar_url}
              valueSlot={
                <Button
                  variant="secondary"
                  onClick={() => {
                    setAttendees([...attendees, attendee]);
                    setSuggestedAttendees(
                      suggestedAttendees.filter((x) => x.email != attendee.email)
                    );
                  }}
                >
                  {t("common:add")}
                </Button>
              }
            />
          ))}
          {calendar.provider == ApiCalendarProviders.google_calendar && (
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switch
                  checked={value}
                  onChange={onChange}
                  title={t("calendar:google_meet")}
                  description={t("calendar:link_will_be_created_after_event_creation")}
                />
              )}
              name={"create_conference"}
            />
          )}
        </Stack>
        <Title size={"xs"} header={t("common:settings")} paddingTop paddingBottom />
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              disabled={!!eventId}
              value={value}
              emptyTitle={t("common:calendar")}
              onChange={(newValue) => {
                onChange(newValue);
                if (calendars) {
                  const calendarFromCalendars = calendars.find((x) => x.id == newValue);
                  if (calendarFromCalendars) {
                    setValue("timezone", calendarFromCalendars.timezone);
                    setCalendar(calendarFromCalendars);
                  }
                }
              }}
              options={calendars}
              error={errors.calendar_id?.message}
              searchable
            />
          )}
          name={"calendar_id"}
        />
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value == ApiEventVisibility.private ? "private" : "public"}
              emptyTitle={t("calendar:event_visibility")}
              onChange={(newValue) => {
                onChange(newValue as ApiEventVisibility);
              }}
              options={privacyOptions}
              error={errors.visibility?.message}
              searchable
            />
          )}
          name={"visibility"}
        />
        <Textarea
          label={t("common:description")}
          maxHeight={150}
          placeholder={t("common:description")}
          {...register("description")}
        />
      </form>
    </Modal>
  );
}
