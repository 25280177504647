import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Stack from "../stack/Stack.tsx";
import { RadioProps } from "./radio.types.ts";

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ children, className, ...rest }, ref) => {
    // const theme = useTheme("radio");
    return (
      <label className={twMerge("relative flex items-center cursor-pointer", className)}>
        <Stack direction={"horizontal"} className={"py-2"} gap={"smd"} items={"center"}>
          <input
            ref={ref}
            type="radio"
            className={twMerge(
              "peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-dark/10 shadow-md checked:bg-dark checked:text-light"
            )}
            {...rest}
          />
          <div
            className={
              "absolute w-2 h-2 bg-light rounded-full text-center align-middle text-light left-1 opacity-0 peer-checked:opacity-100"
            }
          ></div>
          {children}
        </Stack>
      </label>
    );
  }
);
