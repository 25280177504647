import Stack from "../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Box from "../../../ui/box/Box.tsx";
import { twMerge } from "tailwind-merge";
import { useApplicantsSearchInfinite } from "../../../queries/recruit/use-applicants.query.ts";
import {
  ApiApplicantFilter,
  ApiApplicantShort,
  ApplicantShortEmpty,
} from "../../../types/recruit/applicant.types.ts";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import { PersonalItem } from "../../../components/personal-item/PersonalItem.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { ApplicantDetails } from "./components/ApplicantDetails.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { ApplicantsFilterModal } from "./widgets/ApplicantsFilterModal.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { Search } from "../../../components/search/Search.tsx";
import { useDebounce } from "react-use";
import { isEmpty, omitBy } from "lodash";
import { ApplicantEditModal } from "./widgets/ApplicantEditModal.tsx";
import { useCandidateCreate } from "../../../queries/recruit/use-candidates.query.ts";
import { useJobDetails } from "../../../queries/recruit/use-jobs.query.ts";
import { PipelineStageTypes } from "../../../types/recruit/stages.types.ts";
import { useInView } from "react-intersection-observer";
import toast from "react-hot-toast";
import ButtonIcon from "../../../ui/button/ButtonIcon.tsx";
import { ChangeStageModal } from "./widgets/ChangeStageModal/ChangeStageModal.tsx";
import { ActionItem } from "../../../components/action-item/ActionItem.tsx";
import { parseUrlHelper } from "../../../helpers/parse-url.helper.ts";
import { Allotment } from "allotment";
import { useEmployeeAppConfigStore } from "../../../stores/employee.store.ts";
import { ApiActionCreateByFilter } from "../../../types/action.types.ts";
import { NotificationCount } from "../../../ui/notification-count/NotificationCount.tsx";

export default function Applicants() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id = undefined } = useParams();
  const currentApplicantId = useMemo(() => (id ? parseInt(id, 10) : undefined), [id]);

  const candidateCreate = useCandidateCreate();
  const modalsService = useModals();

  const [filter, setFilter] = useState<ApiApplicantFilter>({
    disqualification_reason_ids: [],
    job_ids: [],
    page_number: 1,
    pipeline_stage_ids: [],
    reserved: undefined,
    disqualified: undefined,
    source_ids: [],
    text: "",
    date_last_candidate_action_from: undefined,
    date_last_candidate_action_to: undefined,
    label_ids: [],
  });
  const {
    data: applicants,
    isLoading,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useApplicantsSearchInfinite(filter);
  const isDataLoading = isLoading || isFetching || isFetchingNextPage;
  const totalApplicants =
    applicants && applicants.pages.length > 0 && applicants.pages[0]
      ? applicants.pages[0].metadata.total_items
      : 0;
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: jobDetails } = useJobDetails(
    filter.job_ids && filter.job_ids.length == 1 ? filter.job_ids[0] : undefined
  );

  const [multiSelectionModeOn, setMultiSelectionModeOn] = useState<boolean>(false);
  const [selectedApplicants, setSelectedApplicants] = useState<ApiApplicantShort[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const { recruitLeftPaneSize, setRecruitLeftPaneSize } = useEmployeeAppConfigStore();

  const [searchTerm, setSearchTerm] = useState(filter.text);
  useDebounce(
    () => {
      const newParams: Record<string, string | string[]> = omitBy(
        {
          ...Object.fromEntries(searchParams),
          text: searchTerm,
        },
        isEmpty
      ) as Record<string, string | string[]>;
      setSearchParams(newParams);
    },
    300,
    [searchTerm]
  );

  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      if (event.source !== window) return;
      if (event.data.type !== "FROM_EXTENSION") return;
      if (!event.data.payload) return;
      if (!event.data.payload.action) return;

      const action: string = event.data.payload.action;
      const actionForApplicants =
        ["APPLICANT_CREATED", "CANDIDATE_CREATED", "COMMENT_ADDED"].indexOf(action) !== -1;

      actionForApplicants && refetch();
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  useEffect(() => {
    const stageIdsParams: number[] = parseUrlHelper.getNumberArray(searchParams, "stage_id");
    const jobIds = parseUrlHelper.getNumberArray(searchParams, "job_id");
    const text = searchParams.get("text") || undefined;
    const location = searchParams.get("core:location") || undefined;
    const sourceIds = parseUrlHelper.getNumberArray(searchParams, "source_id");
    const disqualificationReasonIds = parseUrlHelper.getNumberArray(
      searchParams,
      "disqualification_reason_id"
    );
    const position = searchParams.get("core:position") || undefined;
    const disqualified = searchParams.get("disqualified")
      ? Boolean(searchParams.get("disqualified") == "true")
      : undefined;
    const reserved = searchParams.get("reserved")
      ? Boolean(searchParams.get("reserved") == "true")
      : undefined;
    const dateLastCandidateActionFrom = parseUrlHelper.getDate(
      searchParams,
      "last_candidate_action_from"
    );
    const dateLastCandidateActionTo = parseUrlHelper.getDate(
      searchParams,
      "last_candidate_action_to"
    );
    const labelIds = parseUrlHelper.getNumberArray(searchParams, "label_id");

    setSearchTerm(text);
    handleDismissSelectable();

    setFilter({
      ...filter,
      pipeline_stage_ids: stageIdsParams,
      location: location,
      source_ids: sourceIds,
      disqualification_reason_ids: disqualificationReasonIds,
      position: position,
      job_ids: jobIds ? jobIds : [],
      page_number: 1,
      text: text,
      date_last_candidate_action_from: dateLastCandidateActionFrom,
      date_last_candidate_action_to: dateLastCandidateActionTo,
      reserved,
      disqualified,
      label_ids: labelIds,
    });
  }, [searchParams]);

  const handleApplicantClick = useCallback(
    (applicant: ApiApplicantShort) => {
      navigate(`/recruitment/candidates/${applicant.id}/${location.search}`);
      analyticsService.trackEvent(analyticEvents.applicants.viewedApplicant, {
        [analyticProperties.id]: applicant.id,
      });
    },
    [location]
  );

  const handleApplicantFilterClick = useCallback(() => {
    modalsService.openModal(ApplicantsFilterModal, { filter });
  }, [filter]);

  const handleNewApplicantClick = useCallback(() => {
    modalsService.openModal(ApplicantEditModal, {
      applicant: ApplicantShortEmpty,
      onSave: (applicantSaved: ApiApplicantShort) => {
        if (jobDetails) {
          candidateCreate.mutate(
            {
              job_id: jobDetails.id,
              applicant_id: applicantSaved.id,
              pipeline_stage_id:
                jobDetails.pipeline.stages.find(
                  (stage) => stage.type === PipelineStageTypes.applied
                )?.id || jobDetails.pipeline.stages[0].id,
              pipeline_id: jobDetails.pipeline.id,
            },
            {
              onSuccess: (candidate) => {
                analyticsService.trackEvent(analyticEvents.applicants.assignedToJob, {
                  [analyticProperties.id]: candidate.id,
                  [analyticProperties.source]: "App",
                });
                navigate(`/recruitment/candidates/${applicantSaved.id}/?job_id=${jobDetails.id}`);
              },
            }
          );
        } else {
          navigate(`/recruitment/candidates/${applicantSaved.id}`);
        }
      },
    });
  }, [filter, jobDetails]);

  /* ----- Select Applicants ----- */
  const handleSelectApplicant = useCallback(
    (selected: boolean, applicant: ApiApplicantShort) => {
      if (isSelectAll) selected = !selected;
      if (selected) {
        setSelectedApplicants([...selectedApplicants, applicant]);
      } else {
        setSelectedApplicants(
          selectedApplicants.filter((applicantIn) => applicantIn.id !== applicant.id)
        );
      }
    },
    [selectedApplicants]
  );

  useEffect(() => {
    setMultiSelectionModeOn(selectedApplicants.length > 0 || isSelectAll);

    if (selectedApplicants.length > 0 || isSelectAll) {
      showSelectedCandidates();
    } else {
      toast.remove("toastBar");
    }
  }, [selectedApplicants]);

  const showSelectedCandidates = useCallback(() => {
    toast.custom(
      () => (
        <Stack
          className={"p-2 pl-4 bg-dark/95 rounded-md text-light"}
          gap={"sm"}
          items={"center"}
          direction={"horizontal"}
        >
          {t("plurals:plural_candidates_selected", {
            count: isSelectAll
              ? totalApplicants - selectedApplicants.length
              : selectedApplicants.length,
          })}
          <Button onClick={handleSelectAll}>{t("common:select_all")}</Button>
          <Button onClick={handleChangeStage}>{t("ats:change_stage")}</Button>
          <ButtonIcon
            onClick={handleDismissSelectable}
            icon={<FontAwesomeIcon icon={"fa-light fa-close"} />}
          />
        </Stack>
      ),
      {
        id: "toastBar",
        duration: Infinity,
        position: "bottom-center",
      }
    );
  }, [selectedApplicants]);

  const handleSelectAll = useCallback(() => {
    setIsSelectAll(true);
    setSelectedApplicants([]);
  }, [selectedApplicants]);

  const handleDismissSelectable = useCallback(() => {
    setSelectedApplicants([]);
    setIsSelectAll(false);
    toast.remove("toastBar");
  }, []);

  const handleChangeStage = useCallback(() => {
    if (!jobDetails) return;
    toast.remove("toastBar");

    const candidateFilter: ApiActionCreateByFilter = {
      job_id: jobDetails.id,
      current_pipeline_stage_ids:
        filter.pipeline_stage_ids?.length === 1 ? [filter.pipeline_stage_ids[0]] : undefined,
      reserved: filter.reserved,
      disqualified: filter.disqualified,
    };
    if (isSelectAll) {
      candidateFilter.exclude_applicants_ids = selectedApplicants.map((applicant) => applicant.id);
      candidateFilter.analyticsTotalUpdatedCount = totalApplicants - selectedApplicants.length;
    } else {
      candidateFilter.applicants_ids = selectedApplicants.map((applicant) => applicant.id);
      candidateFilter.analyticsTotalUpdatedCount = selectedApplicants.length;
    }

    modalsService.openModal(ChangeStageModal, {
      pipelineId: jobDetails.pipeline.id,
      candidatesFilter: candidateFilter,
      onUpdatedByFilter: () => {
        setSelectedApplicants([]);
      },
    });
  }, [filter, jobDetails, selectedApplicants]);

  /* --- Infinity Scroll --- */

  const { inView, ref } = useInView({
    threshold: 0.9,
    skip: isDataLoading || !hasNextPage,
    delay: 500,
  });

  useEffect(() => {
    if (isDataLoading) return;
    if (
      applicants &&
      applicants.pages.length > 0 &&
      applicants.pages[0] &&
      applicants.pages[0].metadata.total_items == 0
    )
      return;
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const handleChangePanelSize = (size: number) => {
    id && setRecruitLeftPaneSize(size | 0);

    analyticsService.trackEvent(analyticEvents.pageAction, {
      [analyticProperties.actionType]: "Employee App Config Changed",
      [analyticProperties.title]: "Recruiting Left Pane Size",
      [analyticProperties.id]: size,
    });
  };

  const filterEntitiesCount: number = useMemo(() => {
    let count = 0;

    if ((filter.job_ids?.length ?? 0) > 1) count++;
    if ((filter.pipeline_stage_ids?.length ?? 0) > 0 && (filter.job_ids?.length ?? 0) !== 1)
      count++;
    if ((filter.disqualification_reason_ids?.length ?? 0) > 0) count++;
    if ((filter.source_ids?.length ?? 0) > 0) count++;
    if (filter.location) count++;
    if (filter.position) count++;
    if (filter.date_last_candidate_action_from || filter.date_last_candidate_action_to) count++;
    if ((filter.label_ids?.length ?? 0) > 0) count++;
    return count;
  }, [filter]);

  return (
    <Box className={"h-full flex flex-col overflow-hidden"}>
      <Stack direction={"horizontal"} className={"flex-grow h-full overflow-hidden"}>
        <Allotment
          proportionalLayout={false}
          onDragEnd={(sizes: number[]) => {
            sizes.length && handleChangePanelSize(sizes[0]);
          }}
        >
          <Allotment.Pane minSize={200} preferredSize={recruitLeftPaneSize}>
            <Stack
              className={twMerge(
                "flex-shrink-0 overflow-y-auto divide-y divide-inherit border-dark/5 bg-light/80 h-full",
                `${id ? "" : "w-full bg-light/90"}`
              )}
            >
              <Box>
                <Stack
                  className={"h-16 px-6"}
                  items={"center"}
                  justify={"between"}
                  direction={"horizontal"}
                >
                  {applicants && applicants.pages.length > 0 && applicants.pages[0] ? (
                    <Text
                      className={"text-title text-lg text-ellipsis overflow-hidden text-nowrap"}
                    >
                      {t("plurals:plural_candidates", { count: totalApplicants })}
                    </Text>
                  ) : (
                    <i></i>
                  )}

                  <Stack direction={"horizontal"} gap={"sm"}>
                    <Button
                      variant={filterEntitiesCount == 0 ? "secondary" : "primary"}
                      leftIcon={<FontAwesomeIcon icon="fa-light fa-bars-filter" />}
                      rightIcon={
                        filterEntitiesCount > 0 ? (
                          <NotificationCount count={filterEntitiesCount} size={"sm"} />
                        ) : (
                          <></>
                        )
                      }
                      onClick={() => {
                        handleApplicantFilterClick();
                      }}
                    >
                      {!currentApplicantId && t("common:filter")}
                    </Button>
                    <Button
                      variant={currentApplicantId ? "secondary" : "primary"}
                      onClick={handleNewApplicantClick}
                      leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
                    >
                      {!currentApplicantId && t("ats:add_candidate")}
                    </Button>
                  </Stack>
                </Stack>
                <Stack className={"px-6 w-full h-12"} direction={"horizontal"} gap={"xl"}>
                  <Search
                    className={"w-full"}
                    placeholder={t("ats:search_placeholder_name_phone_links_email") + "..."}
                    value={searchTerm}
                    onInput={(e) => {
                      setSearchTerm(e.currentTarget.value);
                    }}
                  />
                </Stack>
              </Box>
              {applicants &&
                applicants.pages.map((page, pageIndex) => (
                  <Fragment key={`'applicants-page-${pageIndex}`}>
                    {page?.items?.map((applicant, index) => (
                      <Stack
                        key={`candidate-list-item-${pageIndex}-${index}`}
                        direction={"horizontal"}
                        className={
                          currentApplicantId === applicant.id
                            ? "bg-light/80"
                            : "cursor-pointer hover:bg-light/50"
                        }
                        onClick={() => {
                          handleApplicantClick(applicant);
                        }}
                      >
                        <Box className={twMerge("shrink-0", id ? "w-full" : "w-1/3")}>
                          <PersonalItem
                            primaryText={`${applicant.first_name} ${applicant.last_name}`}
                            text2={applicant.current_position.position}
                            avatarUrl={applicant.photo_url}
                            text3={applicant.current_position.company}
                            onSelect={
                              jobDetails
                                ? (selected: boolean) => {
                                    handleSelectApplicant(selected, applicant);
                                  }
                                : undefined
                            }
                            selected={
                              (isSelectAll &&
                                !selectedApplicants.some(
                                  (applicantIn) => applicantIn.id === applicant.id
                                )) ||
                              (!isSelectAll &&
                                selectedApplicants.some(
                                  (applicantIn) => applicantIn.id === applicant.id
                                ))
                            }
                            selectViewIsVisible={multiSelectionModeOn}
                            warning={applicant.stage_is_overdue}
                          />
                        </Box>
                        {!id && applicant.last_action && (
                          <Stack className={"py-1.5 px-6 w-full"}>
                            <ActionItem action={applicant.last_action} dimmed />
                          </Stack>
                        )}
                      </Stack>
                    ))}
                  </Fragment>
                ))}
              <span ref={ref}></span>
              {isDataLoading && <Skeleton className="h-10" />}
            </Stack>
          </Allotment.Pane>
          <Allotment.Pane visible={!!id}>
            <ApplicantDetails />
          </Allotment.Pane>
        </Allotment>
      </Stack>
    </Box>
  );
}
