import { ListItem } from "../../../../components/list-item/ListItem.tsx";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import {
  ApiApplicantDataProtection,
  DataProtectionState,
} from "../../../../types/data-protection.types.ts";
import { useMemo } from "react";
import Button from "../../../../ui/button/Button.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { ApiApplicant } from "../../../../types/recruit/applicant.types.ts";
import { ApplicantDataProtectionOption } from "./ApplicantDataProtectionOption.tsx";
import useDataProtectionHook from "../../../../hooks/use-data-protection.hook.ts";

export interface ApplicantDataProtectionListItemProps {
  dataProtection: ApiApplicantDataProtection;
  applicantFull: ApiApplicant;
}

export function ApplicantDataProtectionListItem({
  dataProtection,
  applicantFull,
}: ApplicantDataProtectionListItemProps) {
  const { t } = useTranslation();
  const { getTitleByType } = useDataProtectionHook();

  const sensitiveDataProtectionType = useMemo(() => {
    let trigger = (
      <Button
        variant={"plain"}
        leftIcon={<FontAwesomeIcon icon={"fa-light fa-paper-plane"} />}
      ></Button>
    );
    let stateSlot = <Text>{t("data_protection:consent_no")}</Text>;

    if (dataProtection.state !== DataProtectionState.empty) {
      trigger = (
        <Button
          variant={"plain"}
          leftIcon={<FontAwesomeIcon icon="fa-light fa-circle-info" />}
        ></Button>
      );
    }

    switch (dataProtection.state) {
      case DataProtectionState.requested:
        stateSlot = (
          <Text className="text-warning font-semibold">
            {t("data_protection:consent_requested")}
          </Text>
        );
        break;
      case DataProtectionState.given:
        stateSlot = (
          <Text className={"text-positive font-semibold"}>
            {t("data_protection:consent_received")}
          </Text>
        );
        break;
      case DataProtectionState.expiring:
        stateSlot = (
          <Text className={"text-warning font-semibold"}>
            {t("data_protection:consent_expiring")}
          </Text>
        );
        break;
      case DataProtectionState.expired:
        stateSlot = (
          <Text className={"text-danger font-semibold"}>
            {t("data_protection:consent_expired")}
          </Text>
        );
        break;
      case DataProtectionState.declined:
        stateSlot = (
          <Text className={"text-danger font-semibold"}>
            {t("data_protection:consent_declined")}
          </Text>
        );
        break;
    }

    return { trigger, stateSlot };
  }, [dataProtection]);

  return (
    <ListItem
      title={`${t("data_protection:personal_data_protection")} — ${getTitleByType(dataProtection.type)}`}
      captionSlot={sensitiveDataProtectionType.stateSlot}
      valueSlot={
        <DropdownMenu trigger={sensitiveDataProtectionType.trigger}>
          <Stack gap={"sm"}>
            <ApplicantDataProtectionOption
              dataProtection={dataProtection}
              applicantId={applicantFull.id}
            />
          </Stack>
        </DropdownMenu>
      }
    />
  );
}
