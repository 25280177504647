export enum ApiNotificationTypes {
  event_invite = "event_invite",
  password_changed = "password_changed",
  simple = "simple",
}

export interface ApiNotificationEvent {
  id: number;
  title: string;
  datetime_start: Date;
  datetime_end: Date;
}

export interface ApiNotificationSimple {
  icon_url?: string;
  emoji?: string;
  pre_title?: string;
  title?: string;
  caption?: string;
  button_title?: string;
  href?: string;
  blank?: boolean;
}

export interface ApiNotification {
  id: number;
  notification_type: ApiNotificationTypes;

  event?: ApiNotificationEvent;
  simple?: ApiNotificationSimple;
}

export interface ApiNotificationsFilter {
  page_number: number;
}
