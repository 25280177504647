import { useMemo } from "react";
import { ApiJobShort } from "../../../../types/recruit/job.types.ts";
import { ApiEmployeeShort } from "../../../../types/employees/employee.types.ts";
import {
  ApiAnalyticsGroupBy,
  ApiAnalyticsRecruitingFilter,
  ApiAnalyticsRecruitingStageFlowReport,
  ApiAnalyticsRecruitingStageFlowReportRowItem,
} from "../../../../types/analytics/analytics.recruiting.types.ts";
import { ColumnDef, createColumnHelper, DisplayColumnDef } from "@tanstack/react-table";
import { Title } from "../../../../components/title/Title.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import Table from "../../../../ui/table/Table.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { useTranslation } from "react-i18next";
import { useRecruitingReports } from "../recruiting-reports.hook.ts";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import LinkButtonTo from "../../../../ui/link/LinkButtonTo.tsx";

export interface RecruitingStageFlowReportTableProps {
  report: ApiAnalyticsRecruitingStageFlowReport | undefined;
  filter: ApiAnalyticsRecruitingFilter;
  isLoading: boolean;
}

interface VMRecruitingStageFlowReportRow {
  title?: string;
  caption?: string;
  isGroup: boolean;
  isFooter?: boolean;
  stages: ApiAnalyticsRecruitingStageFlowReportRowItem[];
  employees: ApiEmployeeShort[];
  jobs: ApiJobShort[];
  period?: string;
}

export const RecruitingStageFlowReportTable = ({
  report,
  filter,
  isLoading,
}: RecruitingStageFlowReportTableProps) => {
  const { renderEmployeeName, formatPeriodInReportingGroups } = useFormatter();
  const { t } = useTranslation();
  const { handlePreviewApplicants } = useRecruitingReports();

  const reportCalculated = useMemo(() => {
    if (!report || report.report.rows.length == 0) return [];

    const jobs: ApiJobShort[] = [];
    for (let i = 0; i < report.report.rows.length; i++) {
      const item = report.report.rows[i];

      const job = jobs.find((x) => x.id === item.job?.id);
      if (!job && item.job) {
        jobs.push(item.job);
      }
    }

    const recruiters: ApiEmployeeShort[] = [];
    for (let i = 0; i < report.report.rows.length; i++) {
      const item = report.report.rows[i];

      const recruiter = recruiters.find((x) => x.id === item.employee?.id);
      if (!recruiter && item.employee) {
        recruiters.push(item.employee);
      }
    }

    // const allStagesFlatMap = report.report.rows.flatMap((x) => x.stages);
    const result: VMRecruitingStageFlowReportRow[] = [];

    // Без группировки
    if (filter.group_by == null) {
      let prevDate: string | undefined = undefined;
      for (let i = 0; i < report.report.rows.length; i++) {
        const item = report.report.rows[i];

        const [, caption] = formatPeriodInReportingGroups(
          item.date_report_from,
          item.date_report_to,
          filter.aggregation_date_mode
        );

        // Делаем группировку по сотруднику, а потом по вакансиям его
        if (!prevDate || prevDate != caption) {
          prevDate = caption;

          result.push({
            title: caption,
            // caption: null,
            isGroup: true,
            employees: [],
            jobs: [],
            stages: [],
          });
        }

        result.push({
          title: item.employee ? renderEmployeeName(item.employee) : "",
          caption: item.job?.title, //formatPeriodInReporting(item.date_report_from, item.date_report_to, filter.aggregation_date_mode),
          isGroup: false,
          employees: item.employee ? [item.employee] : [],
          jobs: item.job ? [item.job] : [],
          stages: item.stages,
        });
      }
    } else if (filter.group_by == ApiAnalyticsGroupBy.job) {
      let prevDate: string | undefined = undefined;
      for (let i = 0; i < report.report.rows.length; i++) {
        const item = report.report.rows[i];

        const [, caption] = formatPeriodInReportingGroups(
          item.date_report_from,
          item.date_report_to,
          filter.aggregation_date_mode
        );

        // Делаем группировку по сотруднику, а потом по вакансиям его
        if (!prevDate || prevDate != caption) {
          prevDate = caption;

          result.push({
            title: caption,
            // caption: null,
            isGroup: true,
            employees: [],
            jobs: [],
            stages: [],
          });
        }

        result.push({
          caption: item.job ? item.job.title : "",
          title: item.job ? item.job.emoji : "", //formatPeriodInReporting(item.date_report_from, item.date_report_to, filter.aggregation_date_mode),
          isGroup: false,
          employees: item.employee ? [item.employee] : [],
          jobs: item.job ? [item.job] : [],
          stages: item.stages,
        });
      }
    } else {
      let prevDate: string | undefined = undefined;
      for (let i = 0; i < report.report.rows.length; i++) {
        const item = report.report.rows[i];

        const [, caption] = formatPeriodInReportingGroups(
          item.date_report_from,
          item.date_report_to,
          filter.aggregation_date_mode
        );

        // Делаем группировку по сотруднику, а потом по вакансиям его
        if (!prevDate || prevDate != caption) {
          prevDate = caption;

          result.push({
            title: caption,
            // caption: null,
            isGroup: true,
            employees: [],
            jobs: [],
            stages: [],
          });
        }

        result.push({
          title: item.employee ? renderEmployeeName(item.employee) : "",
          caption: "", //formatPeriodInReporting(item.date_report_from, item.date_report_to, filter.aggregation_date_mode),
          isGroup: false,
          employees: item.employee ? [item.employee] : [],
          jobs: item.job ? [item.job] : [],
          stages: item.stages,
        });
      }
    }

    // result.push({
    //   title: t("common:total"),
    //   caption: "",
    //   stages: report.config.stages.map((x) => ({
    //     pipeline_stage: x,
    //     value: allStagesFlatMap
    //       .filter((y) => y.pipeline_stage.id == x.id)
    //       .reduce((acc, y) => acc + y.value, 0),
    //     value2: 0,
    //     applicants_ids: allStagesFlatMap
    //       .filter((y) => y.pipeline_stage.id == x.id)
    //       .flatMap((y) => y.applicants_ids),
    //   })),
    //   isGroup: true,
    //   employees: [],
    //   jobs: [],
    // });

    return result;
  }, [report, filter.group_by]);

  const columnHelper = createColumnHelper<VMRecruitingStageFlowReportRow>();
  const columns = useMemo<ColumnDef<VMRecruitingStageFlowReportRow>[]>(() => {
    const allStagesFlatMap = report ? report.report.rows.flatMap((x) => x.stages) : [];

    const inner: DisplayColumnDef<VMRecruitingStageFlowReportRow, unknown>[] = [];
    inner.push(
      columnHelper.display({
        id: "week",
        header: t("common:period"),
        cell: (cell) => (
          <Title
            size={cell.row.original.isGroup ? "md" : "sm"}
            header={cell.row.original.title || ""}
            caption={cell.row.original.caption}
            paddingBottom={cell.row.original.isGroup}
            paddingTop={cell.row.original.isGroup}
          />
        ),
        footer: () => (
          <Title
            size={"md"}
            header={t("common:total")}
            caption={""}
            paddingBottom={true}
            paddingTop={true}
          />
        ),
        size: "auto" as unknown as number,
      })
    );

    if (report?.config && report.config.stages.length > 0) {
      for (let i = 0; i < report.config.stages.length; i++) {
        const stage = report.config.stages[i];

        const footerValue = allStagesFlatMap
          .filter((y) => y.pipeline_stage.id == stage.id)
          .reduce((acc, y) => acc + y.value, 0);

        const applicantsIds = allStagesFlatMap
          .filter((y) => y.pipeline_stage.id == stage.id)
          .flatMap((y) => y.applicants_ids);

        inner.push(
          columnHelper.display({
            id: `stage-${i}`,
            header: () => <Text className={"capitalize"}>{stage.title}</Text>,
            cell: (cell) => (
              <>
                {(cell.row.original.stages.find((x) => x.pipeline_stage.id == stage.id)?.value ||
                  0) > 0 ? (
                  <LinkButtonTo
                    variant={"secondary"}
                    className={"text-default"}
                    onClick={() => {
                      const item = cell.row.original.stages.find(
                        (x) => x.pipeline_stage.id == stage.id
                      );
                      if (item)
                        handlePreviewApplicants(
                          item.applicants_ids,
                          AnalyticReportTypes.recruitingStageFlow
                        );
                    }}
                  >
                    {cell.row.original.stages.find((x) => x.pipeline_stage.id == stage.id)?.value ||
                      "-"}
                  </LinkButtonTo>
                ) : (
                  ""
                )}

                {!cell.row.original.isGroup &&
                !cell.row.original.stages.find((x) => x.pipeline_stage.id == stage.id)?.value ? (
                  <span className={"text-dark/40"}>-</span>
                ) : (
                  ""
                )}
              </>
            ),
            footer: () => (
              <>
                {footerValue > 0 ? (
                  <LinkButtonTo
                    variant={"secondary"}
                    className={"text-default"}
                    onClick={() => {
                      if (applicantsIds)
                        handlePreviewApplicants(
                          applicantsIds,
                          AnalyticReportTypes.recruitingStageFlow
                        );
                    }}
                  >
                    {footerValue}
                  </LinkButtonTo>
                ) : (
                  ""
                )}
              </>
            ),
            enableResizing: false,
            size: 80,
          })
        );
      }
    }
    return inner;
  }, [reportCalculated, filter.group_by]);

  return (
    <Table
      data={reportCalculated || []}
      columns={columns}
      showSkeleton={isLoading}
      stickyHeader={true}
    />
  );
};
