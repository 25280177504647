import { Page } from "../../../../components/page/Page.tsx";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import {
  useEmployeeDismissalReasonDelete,
  useEmployeeDismissalReasons,
} from "../../../../queries/employees/use-employee-dismissal-reason.query.ts";
import { useCallback, useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Text from "../../../../ui/typography/Text.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { useTranslation } from "react-i18next";
import useModals from "../../../../ui/modal/modal.store.ts";
import Table from "../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../../components/empty-state/EmptyState.tsx";
import { ApiEmployeeDismissalReason } from "../../../../types/employees/employee-dismissal-reason.types.ts";
import { EmployeeDismissalReasonEditModal } from "./widgets/EmployeeDismissalReasonEditModal.tsx";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";

export function EmployeeDismissalReasons() {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { data: dismissalReasons, isFetching, isLoading } = useEmployeeDismissalReasons();
  const employeeDismissalReasonDelete = useEmployeeDismissalReasonDelete();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.employeeDismissalReasons.viewed);
  });

  const columnHelper = createColumnHelper<ApiEmployeeDismissalReason>();
  const columns = useMemo<ColumnDef<ApiEmployeeDismissalReason>[]>(
    () => [
      {
        header: t("common:title"),
        cell: (row) => <Text className={"text-title"}>{row.row.original.title}</Text>,
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      columnHelper.display({
        header: t("common:actions"),
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              variant={"secondary"}
              onClick={() => {
                handleEditOrCreate(cell.row.original);
              }}
            >
              {t("common:edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={async () => {
                  handleDeleteAction(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("common:delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [dismissalReasons]
  );

  const handleEditOrCreate = useCallback(
    (dismissalReason: ApiEmployeeDismissalReason | undefined = undefined) => {
      const toEdit = dismissalReason || {
        id: 0,
        title: "",
      };

      modalsService.openModal(EmployeeDismissalReasonEditModal, {
        dismissalReason: toEdit,
      });
    },
    []
  );

  const handleDeleteAction = useCallback((dismissalReason: ApiEmployeeDismissalReason) => {
    modalsService.openModal(ConfirmationPopup, {
      question: t("core:termination_reason_delete_confirm"),
      text: t(""),
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        try {
          await employeeDismissalReasonDelete.mutateAsync(dismissalReason.id);

          analyticsService.trackEvent(analyticEvents.employeeDismissalReasons.deleted, {
            [analyticProperties.id]: dismissalReason.id,
          });
        } catch {
          return;
        }
      },
    });
  }, []);

  return (
    <Page>
      <Page.Header showBack={true} title={t("core:termination_reasons")}>
        <Button
          onClick={() => handleEditOrCreate()}
          leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
        >
          {t("common:create_new")}
        </Button>
      </Page.Header>
      <Page.Content>
        <Table
          data={dismissalReasons || []}
          columns={columns}
          isFetching={isFetching || employeeDismissalReasonDelete.isPending}
          showSkeleton={isLoading}
          notFoundComponent={<EmptyStateNothingFound />}
          emptyStateComponent={<EmptyState />}
        />
      </Page.Content>
    </Page>
  );
}
