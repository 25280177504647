import { Title } from "../../../components/title/Title.tsx";
import { ListItem } from "../../../components/list-item/ListItem.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { useCallback, useState } from "react";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { useTranslation } from "react-i18next";
import useErrorHandle from "../../../hooks/use-error-handle.hook.tsx";
import {
  useEmailDisconnect,
  useEmailIntegrations,
} from "../../../queries/integrations/use-emails.query.ts";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import useToasts from "../../../hooks/use-toasts.hook.tsx";
import { apiIntegrations } from "../../../api/integrations/integration.api.ts";
import { ApiIntegrationType } from "../../../types/integrations/integration.types.ts";

export function EmailIntegration() {
  const { t } = useTranslation();
  const { renderDateAndTime } = useFormatter();
  const { handleErrors } = useErrorHandle();
  const { showInfo } = useToasts();

  const { data: integrations } = useEmailIntegrations();
  const emailDisconnect = useEmailDisconnect();

  const [isConnecting, setIsConnecting] = useState(false);

  const handleEmailConnect = useCallback(async () => {
    analyticsService.trackEvent(analyticEvents.pageAction, {
      [analyticProperties.actionType]: "Connect Email Started",
      [analyticProperties.source]: "Gmail",
    });

    setIsConnecting(true);

    const { data, error } = await apiIntegrations.install(ApiIntegrationType.gmail);

    setIsConnecting(false);

    if (data) {
      window.location.href = data.authorization_url;
    } else handleErrors(error);
  }, []);

  const handleEmailDisconnect = useCallback(async (email: string) => {
    emailDisconnect.mutate(email, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.email.disconnected, {
          [analyticProperties.id]: email,
        });
        showInfo(t("integrations:account_disconnected"));
      },
    });
  }, []);

  return (
    <>
      <Title header={t("integrations:connected_emails")} paddingBottom paddingTop />
      {integrations && integrations.length > 0 ? (
        <>
          {integrations.map((integration) => (
            <ListItem
              key={`integration-email-${integration.identity}`}
              title={integration.identity}
              caption={`${t("integrations:gmail_account")}. ${t("integrations:last_synced")}: ${renderDateAndTime(integration.datetime_last_check)}`}
              valueSlot={
                <Button
                  onClick={() => {
                    handleEmailDisconnect(integration.identity);
                  }}
                  variant={"secondary"}
                >
                  {t("integrations:disconnect")}
                </Button>
              }
            />
          ))}
          <Button
            variant="secondary"
            leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
            className={"mt-4"}
            onClick={handleEmailConnect}
          >
            {t("integrations:connect")}
          </Button>
        </>
      ) : (
        <ListItem
          title={t("integrations:gmail_account")}
          captionSlot={
            <Text className={"font-semibold text-warning"}>{t("integrations:not_connected")}</Text>
          }
          valueSlot={
            <Button onClick={handleEmailConnect} disabled={isConnecting} variant={"secondary"}>
              {t("integrations:connect")}
            </Button>
          }
        />
      )}
    </>
  );
}
