import { UIKitThemeBase } from "../themes/theme.types.ts";

export interface ToastVariant {
  info: string;
  error: string;
}

export interface UIKitToastTheme extends UIKitThemeBase {
  variants: {
    variant: ToastVariant;
  };
  buttonContainer: string;
  contentContainer: string;
  content: {
    title: string;
    description: string;
  };
}

export interface ToastProps {
  title: string;
  description?: string;
  type: keyof ToastVariant;
  visible?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const toastTheme: UIKitToastTheme = {
  base: "max-w-md bg-light shadow-lg rounded-lg pointer-events-auto flex flex-shrink-0 pl-4 pr-2",
  variants: {
    variant: {
      info: "bg-dark/95 ",
      error: "bg-danger",
    },
  },
  buttonContainer: "flex items-center flex-shrink-0",
  contentContainer: "flex  py-3 pr-4 items-start space-y-1",
  content: {
    title: "text-white",
    description: "text-secondary",
  },
};
