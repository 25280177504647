import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  RowSelectionState,
  useReactTable,
} from "@tanstack/react-table";
import Skeleton from "../skeleton/Skeleton.tsx";
import { ReactNode, useState } from "react";
import { useTheme } from "../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import { useUpdateEffect } from "react-use";

// https://dev.to/esponges/create-a-reusable-react-table-component-with-typescript-56d4
// Example with sort https://codesandbox.io/p/devbox/final-design-rt-cqqzqe?file=%2Fsrc%2Findex.js
// Skeleton https://dev.to/stephengade/how-to-build-a-powerful-table-in-reactjs-with-tanstack-and-material-ui-4ndp

export interface TableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  showSkeleton?: boolean;
  showNotFoundError?: boolean;
  isFetching?: boolean;
  notFoundComponent?: ReactNode;
  emptyStateComponent?: ReactNode;
  enableMultiRowSelection?: boolean;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: (rowSelection: RowSelectionState) => void;
  columnVisibility?: Record<string, boolean>;
  stickyHeader?: boolean;
  stickyFooter?: boolean;
}

// More links to review
// https://gist.github.com/stephengade/db1372466bc6703a8ca53ff75f1e1763
// https://stackblitz.com/edit/stackblitz-starters-n7eu8t?file=src%2Ftable%2Ftable-root.tsx
// https://makerkit.dev/snippets/reusable-table-component-reactjs
// https://medium.com/@jordammendes/build-powerfull-tables-in-reactjs-with-tanstack-9d57a3a63e35
export default function Table<T extends object>({
  data,
  columns,
  showSkeleton = false,
  isFetching = false,
  showNotFoundError,
  notFoundComponent,
  emptyStateComponent,
  enableMultiRowSelection = false,
  rowSelection,
  onRowSelectionChange,
  columnVisibility = {},
  stickyHeader = false,
}: TableProps<T>) {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(rowSelection || {});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      columnVisibility: columnVisibility,
      rowSelection: rowSelectionState,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => {
      if ("subRows" in row) {
        return row.subRows as T[];
      }
      return undefined;
    },
    getCoreRowModel: getCoreRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiRowSelection: enableMultiRowSelection,
    onRowSelectionChange: setRowSelectionState,
    defaultColumn: {
      minSize: 80,
    },
  });

  const skeletonCount = 3;
  const skeletons: number[] = Array.from({ length: skeletonCount }, (_, i) => i);
  const columnCount = table.getAllColumns().length;

  const theme = useTheme("table");

  useUpdateEffect(() => {
    onRowSelectionChange?.(rowSelectionState);
  }, [rowSelectionState]);

  return (
    <>
      <div
        className={twMerge(
          "max-w-full overflow-x-auto block relative my-2",
          isFetching ? "opacity-50" : undefined
        )}
      >
        <table className={theme.base}>
          <thead
            className={twMerge(
              theme.table.thead.base,
              stickyHeader && "sticky top-0 bg-light -translate-y-[0.125rem]"
            )}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={theme.table.thead.th}
                    style={{ width: `${header.getSize()}px` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {showSkeleton ? (
              <>
                {skeletons.map((skeleton) => (
                  <tr key={skeleton} className={theme.table.tbody.tr}>
                    {Array.from({ length: columnCount }, (_, i) => i).map((elm) => (
                      <td className={theme.table.tbody.td} key={elm}>
                        <Skeleton className="w-full h-4" />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id} className={twMerge(theme.table.tbody.tr)}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={theme.table.tbody.td}
                      key={cell.id}
                      style={{ width: `${cell.column.getSize()}px` }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
          <tfoot className={"sticky bottom-0 bg-light translate-y-[0.125rem]"}>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((column) => (
                  <td key={column.id}>
                    {flexRender(column.column.columnDef.footer, column.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {showNotFoundError && notFoundComponent}

        {!showSkeleton && data.length == 0 && !showNotFoundError && emptyStateComponent}
      </div>
    </>
  );
}
