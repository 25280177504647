import Stack from "../../ui/stack/Stack.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import { ApiAction, ApiActionTypes } from "../../types/action.types.ts";
import Text from "../../ui/typography/Text.tsx";
import Box from "../../ui/box/Box.tsx";
import i18n from "../../i18n.ts";
import { ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ApiEmail } from "../../types/integrations/integration.types.ts";
import { EmailViewModal } from "../email/EmailViewModal.tsx";
import useModals from "../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { ApiFile } from "../../types/common.types.ts";
import { FileItem } from "../file-item/FileItem.tsx";
import { EmployeeAvatar } from "../employee-avatar/EmployeeAvatar.tsx";
import { EventPreviewModal } from "../event/EventPreviewModal.tsx";
import { EmailSendModal } from "../email/EmailSendModal.tsx";
import { twMerge } from "tailwind-merge";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import LinkButtonTo from "../../ui/link/LinkButtonTo.tsx";

export interface ActionItemProps {
  attachments?: ReactNode;
  action: ApiAction;
  dimmed?: boolean;
  actionsSlot?: ReactNode;
}

interface ActionItemCompiled {
  title?: string;
  subtitle?: string;
  description?: string;
}

export const ActionItem = ({
  action,
  attachments,
  actionsSlot,
  dimmed = false,
}: ActionItemProps) => {
  const { renderEmployeeName, renderRelativeDate, renderDateAndTime, renderDate, renderTime } =
    useFormatter();
  const { t } = useTranslation();
  const modalsService = useModals();

  const handleViewEmail = useCallback((message: ApiEmail | undefined = undefined) => {
    if (!message) return;

    analyticsService.trackEvent(analyticEvents.pageAction, {
      [analyticProperties.id]: message.id,
      [analyticProperties.actionType]: "Email Viewed",
    });

    modalsService.openModal(EmailViewModal, {
      email: message,
      onReply: () => {
        modalsService.openModal(EmailSendModal, {
          candidateId: message.candidate_id,
          applicantEmail: message.from_email,
          subject: `Re: ${message.subject}` || "",
          threadId: message.original_thread_id,
        });
      },
    });
  }, []);

  const titleCompiled = useMemo(() => {
    const result: ActionItemCompiled = {};
    switch (action.type) {
      case ApiActionTypes.event:
        if (action.event) {
          result.title = `${renderDate(action.event.datetime_start)} · ${renderTime(action.event.datetime_start)} — ${renderTime(action.event.datetime_end)}`;
          result.description = action.event.title;
        }

        break;
      case ApiActionTypes.created:
        result.title = i18n.t("ats:action_created");
        break;
      case ApiActionTypes.assigned:
        result.title = action.pipeline_stage?.title ?? i18n.t("ats:action_assigned");
        break;
      case ApiActionTypes.stage_changed:
        result.title = action.pipeline_stage?.title ?? "";
        result.description = action.comment;
        break;
      case ApiActionTypes.merged:
        result.title = i18n.t("ats:action_merged");
        if (action.merged_applicant)
          result.description = `${action.merged_applicant.first_name} ${action.merged_applicant.last_name}`;
        break;
      case ApiActionTypes.disqualified:
        if (action.reserved === true) {
          result.title =
            i18n.t("ats:action_reserved") + " — " + (action.disqualification_reason?.title ?? "");
        } else {
          result.title =
            i18n.t("ats:action_disqualified") +
            " — " +
            (action.disqualification_reason?.title ?? "");
        }
        result.description = action.comment;
        break;
      case ApiActionTypes.qualified:
        result.title =
          i18n.t("ats:action_qualified") + " — " + (action.pipeline_stage?.title ?? "");
        result.description = action.comment;
        break;
      case ApiActionTypes.email_sent:
        if (action.message?.is_outgoing) result.title = i18n.t("common:email_sent");
        else result.title = i18n.t("ats:action_email_candidate_replied");
        result.description = action.message?.subject;
        break;
      case ApiActionTypes.comment_added:
        result.title = undefined;
        result.description = action.comment;
        break;
    }
    return result;
  }, [action]);

  const handlePreviewEvent = useCallback((action: ApiAction | undefined) => {
    if (!action?.event) return;

    modalsService.openModal(EventPreviewModal, {
      event: action.event,
    });
  }, []);

  return (
    <Stack className="w-full py-3 overflow-hidden" gap={"sm"}>
      <Box>
        {action.message && action.message.is_unread && (
          <FontAwesomeIcon icon={"fa-solid fa-circle text-new text-sm mr-2"} />
        )}
        {action.event && (
          <LinkButtonTo
            className={twMerge(
              "font-semibold"
              // action.event.is_cancelled ? "line-through" : undefined
            )}
            onClick={() => {
              handlePreviewEvent(action);
            }}
          >
            {titleCompiled.title}
          </LinkButtonTo>
        )}
        {!action.event && titleCompiled.title && (
          <Text className={twMerge(dimmed ? "text-default" : "text-title")}>
            {titleCompiled.title}
          </Text>
        )}
        {titleCompiled.title && titleCompiled.description && (
          <Text className={"text-secondary mx-2"}>·</Text>
        )}

        {titleCompiled.description && (
          <Text className={"text-default whitespace-pre-wrap overflow-ellipsis overflow-hidden"}>
            {titleCompiled.description}{" "}
            {action.type == ApiActionTypes.email_sent && (
              <LinkButtonTo
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  handleViewEmail(action.message);
                }}
              >
                {t("common:view")}
              </LinkButtonTo>
            )}
          </Text>
        )}
        {titleCompiled.subtitle && (
          <Text className={"text-secondary block"}>{titleCompiled.subtitle}</Text>
        )}
      </Box>

      {action.files && action.files.length > 0 && (
        <Box className={"overflow-hidden"}>
          <Stack gap={"xs"} className={"overflow-hidden"}>
            {action.files.map((file: ApiFile) => (
              <Box className={"flex"} key={`comment_file${file.id}`}>
                <FileItem file={file} />
              </Box>
            ))}
          </Stack>
        </Box>
      )}
      {attachments}
      <Stack gap={"sm"} direction={"horizontal"} items={"center"} className={"overflow-hidden"}>
        {!dimmed && (
          <Box className={"mt-0.5 flex shrink-0"}>
            <EmployeeAvatar size={"sm"} employee={action.author} />
          </Box>
        )}
        <Stack direction={"horizontal"} gap={"xs"} items={"center"} className={"overflow-hidden"}>
          <Text className={"text-secondary text-nowrap overflow-ellipsis overflow-hidden"}>
            {renderEmployeeName(action.author)}
          </Text>
          <Text className={"text-secondary "}>·</Text>
          {action.datetime_created_at && (
            <Text
              title={renderDateAndTime(action.datetime_created_at)}
              className={"text-secondary text-nowrap overflow-ellipsis overflow-hidden"}
            >
              {renderRelativeDate(action.datetime_created_at)}
            </Text>
          )}
        </Stack>
        {actionsSlot && <Box className={"shrink-0"}>{actionsSlot}</Box>}
      </Stack>
    </Stack>
  );
};
