import {
  ApiRoleShort,
  EmployeeFieldPermissions,
  Permissions,
  PermissionType,
  RoleApplyRule,
  RoleApplySignType,
  RoleAssignToConditionType,
  RoleType,
} from "../../../types/role.types.ts";
import { ModalProps } from "../../../ui/modal/modal.types.ts";
import * as yup from "yup";
import { SelectItem } from "../../../ui/select/select.types.ts";
import i18n from "../../../i18n.ts";

export interface PageRolesProps {
  tab?: keyof PageRolesTab;
}

export interface PageRolesTab {
  list: string;
  employees: string;
}

export const mockRole = {
  id: 0,
  title: "",
  type: RoleType.custom,
  description: "",
  assign_to_list: [],
  apply_to_list: [],
  employees_count: 0,
  api_permissions: {
    [Permissions.settings_departments]: PermissionType.none,
    [Permissions.settings_divisions]: PermissionType.none,
    [Permissions.settings_positions_levels]: PermissionType.none,
    [Permissions.settings_roles_permissions]: PermissionType.none,
    [Permissions.settings_organization]: PermissionType.none,
    [Permissions.settings_pipelines]: PermissionType.view,
    [Permissions.settings_data_protection]: PermissionType.view,
    [Permissions.add_employees]: PermissionType.none,
    [Permissions.dismiss_employees]: PermissionType.none,
    [Permissions.recruiting_module]: PermissionType.none,
    [Permissions.show_all_jobs]: PermissionType.none,
    [Permissions.manage_jobs]: PermissionType.none,
    [Permissions.show_jobs_salaries]: PermissionType.none,
    [Permissions.show_all_candidates]: PermissionType.none,
    [Permissions.manage_candidates]: PermissionType.none,
    [Permissions.delete_candidates]: PermissionType.none,
    [Permissions.show_candidates_salaries]: PermissionType.none,
    [Permissions.settings_sources]: PermissionType.none,
    [Permissions.settings_email_templates]: PermissionType.none,
    [Permissions.settings_candidate_disqualification_reasons]: PermissionType.none,
    [Permissions.settings_locations]: PermissionType.none,
    [Permissions.settings_legal_entities]: PermissionType.none,
    [Permissions.delete_all_comments]: PermissionType.none,
    [Permissions.reporting_recruiting_stage_flow]: PermissionType.none,
    [Permissions.reporting_recruiting_source_efficiency]: PermissionType.none,
    [Permissions.reporting_recruiting_closing_time]: PermissionType.none,
    [Permissions.reporting_recruiting_disqualification_reasons]: PermissionType.none,
    [Permissions.reporting_export]: PermissionType.none,
    [Permissions.see_dismissed_employees]: PermissionType.none,
    [Permissions.see_organization_structure]: PermissionType.none,
    [Permissions.see_departments_structure]: PermissionType.none,
    [Permissions.see_notes]: PermissionType.none,
  },
  employee_permissions: {
    [EmployeeFieldPermissions.employee_name]: PermissionType.view,
    [EmployeeFieldPermissions.employee_hired_on]: PermissionType.view,
    [EmployeeFieldPermissions.employee_email]: PermissionType.view,
    [EmployeeFieldPermissions.employee_email_personal]: PermissionType.none,
    [EmployeeFieldPermissions.employee_phone]: PermissionType.view,
    [EmployeeFieldPermissions.employee_phone_personal]: PermissionType.none,
    [EmployeeFieldPermissions.employee_gender]: PermissionType.view,
    [EmployeeFieldPermissions.employee_date_birth]: PermissionType.view,
    [EmployeeFieldPermissions.employee_date_birth_display]: PermissionType.none,
    [EmployeeFieldPermissions.employee_avatar]: PermissionType.view,
    [EmployeeFieldPermissions.employee_background]: PermissionType.view,
    [EmployeeFieldPermissions.employee_documents]: PermissionType.none,

    [EmployeeFieldPermissions.employee_number]: PermissionType.none,
    [EmployeeFieldPermissions.employee_address_address_line_1]: PermissionType.none,
    [EmployeeFieldPermissions.employee_address_address_line_2]: PermissionType.none,
    [EmployeeFieldPermissions.employee_address_zip_code]: PermissionType.edit,
    [EmployeeFieldPermissions.employee_address_country]: PermissionType.view,
    [EmployeeFieldPermissions.employee_address_state]: PermissionType.view,
    [EmployeeFieldPermissions.employee_address_city]: PermissionType.view,

    [EmployeeFieldPermissions.employee_position_job_title]: PermissionType.view,
    [EmployeeFieldPermissions.employee_position_date]: PermissionType.view,
    [EmployeeFieldPermissions.employee_position_reporting_to]: PermissionType.view,
    [EmployeeFieldPermissions.employee_position_division]: PermissionType.view,
    [EmployeeFieldPermissions.employee_position_department]: PermissionType.view,
    [EmployeeFieldPermissions.employee_position_location]: PermissionType.view,
    [EmployeeFieldPermissions.employee_position_legal_entity]: PermissionType.none,
    [EmployeeFieldPermissions.employee_position_comment]: PermissionType.none,

    [EmployeeFieldPermissions.employee_salary]: PermissionType.none,
    [EmployeeFieldPermissions.employee_salary_description]: PermissionType.none,
  },
};

export interface RuleEditModalProps extends ModalProps {
  rule?: RoleApplyRule;
  conditionTypes: SelectItem[];
  onModified: (rule: RoleApplyRule) => void;
  onDelete: () => void;
}

export const EditRuleSchemaObject = yup.object({
  condition_type: yup
    .mixed<RoleAssignToConditionType>()
    .oneOf(Object.values(RoleAssignToConditionType))
    .required(),
  sign: yup.number().oneOf([RoleApplySignType.exclude, RoleApplySignType.include]).required(),
  condition_ids: yup.array().of(yup.number().required()).required(),
  condition_entities: yup.array(),
});

export interface EditRuleSchema extends yup.InferType<typeof EditRuleSchemaObject> {}

export const getRoleTitle = (role: ApiRoleShort) => {
  if (role.type == RoleType.myself) return i18n.t("core:rule_myself");
  else if (role.type == RoleType.all_employees) return i18n.t("core:rule_all_employees");
  else if (role.type == RoleType.team_lead) return i18n.t("core:teamleads");
  else if (role.type == RoleType.manager) return i18n.t("core:heads_of_departments");
  else if (role.type == RoleType.admin) return i18n.t("core:rules_admins");
  else return role.title;
};

export const getRoleDescription = (role: ApiRoleShort) => {
  if (role.type == RoleType.myself) return i18n.t("core:role_type_myself");
  else if (role.type == RoleType.all_employees) return i18n.t("core:role_type_all_employees");
  else if (role.type == RoleType.team_lead) return i18n.t("core:role_type_teamleads");
  else if (role.type == RoleType.manager) return i18n.t("core:role_type_manager");
  else if (role.type == RoleType.admin) return i18n.t("core:role_type_admin");
  else return role.description;
};
