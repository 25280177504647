import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import { Tabs } from "../../../../ui/tabs/Tabs.tsx";
import { TabItem } from "../../../../ui/tabs/TabItem.tsx";
import { DataProtectionTabSettings } from "./components/DataProtectionTabSettings.tsx";
import { DataProtectionTabRequests } from "./components/DataProtectionTabRequests.tsx";
import { DataProtectionSettingsProps } from "../../../../types/data-protection.types.ts";

export function DataProtectionSettings({ tab }: DataProtectionSettingsProps) {
  const { t } = useTranslation();

  const handleTabChanged = (tabIndex: number) => {
    const urls = ["/settings/data-protection", "/settings/data-protection/requests"];

    window.history.replaceState({}, "", urls[tabIndex]);
  };

  return (
    <Page>
      <Page.Header title={t("data_protection:data_protection")} showBack={true}></Page.Header>
      <Page.Content>
        <Tabs onActiveTabChange={handleTabChanged}>
          <TabItem title={t("common:settings")}>
            <DataProtectionTabSettings />
          </TabItem>
          <TabItem title={t("common:requests")} active={tab == "requests"}>
            <DataProtectionTabRequests />
          </TabItem>
        </Tabs>
      </Page.Content>
    </Page>
  );
}
