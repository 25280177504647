import Stack from "../../../ui/stack/Stack.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { useTranslation } from "react-i18next";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import Table from "../../../ui/table/Table.tsx";
import {
  ApiJob,
  ApiJobEmpty,
  ApiJobsFilter,
  ApiJobsFilterDefault,
  getJobEmoji,
  JobStatus,
  JobStatusItems,
} from "../../../types/recruit/job.types.ts";
import Text from "../../../ui/typography/Text.tsx";

import LinkTo from "../../../ui/link/LinkTo.tsx";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";
import EmptyStateNothingFound from "../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../components/empty-state/EmptyState.tsx";
import { Page } from "../../../components/page/Page.tsx";
import { Search } from "../../../components/search/Search.tsx";
import { useDebounce, useEffectOnce } from "react-use";
import { useJobsSearch } from "../../../queries/recruit/use-jobs.query.ts";
import { PaginationBlock } from "../../../ui/pagination/PaginationBlock.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { JobEditModal } from "./widgets/JobEditModal.tsx";
import Avatar from "../../../ui/avatar/Avatar.tsx";
import { Tabs } from "../../../ui/tabs/Tabs.tsx";
import { EmployeesListCell } from "../../../components/employees-list-cell/EmployeesListCell.tsx";

enum JobTabsSection {
  my,
  opened,
  all,
}

export default function JobsIndex() {
  const { t } = useTranslation();
  const modalsService = useModals();

  const [activeTab, setActiveTab] = useState<JobTabsSection>(JobTabsSection.my);
  const [filter, setFilter] = useState<ApiJobsFilter>({
    ...ApiJobsFilterDefault,
    status_list: [JobStatus.published],
    my_jobs: true,
  });

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  useDebounce(
    () => {
      if (filter.text != searchTerm) {
        setFilter({ ...filter, text: searchTerm, page_number: 1 });
      }
    },
    300,
    [searchTerm]
  );

  const { data: jobs, isLoading, isFetching } = useJobsSearch(filter);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.jobs.viewed);
  });

  const columnHelper = createColumnHelper<ApiJob>();
  const columns = useMemo<ColumnDef<ApiJob>[]>(
    () => [
      {
        header: "",
        cell: (row) => <Avatar url={undefined} emoji={getJobEmoji(row.row.original)} size={"md"} />,
        accessorKey: "emoji",
        size: 64,
        minSize: 64,
        maxSize: 64,
        enableResizing: false,
      },
      {
        header: t("ats:job"),
        cell: (row) => (
          <LinkTo
            variant={"secondary"}
            to={`/recruitment/candidates/?job_id=${row.row.original.id}`}
            className={"text-title"}
          >
            {row.row.original.title}
          </LinkTo>
        ),
        accessorKey: "title",
        size: "auto" as unknown as number,
        minSize: 80,
        maxSize: 300,
        enableResizing: true,
      },
      {
        header: t("core:department"),
        cell: (row) => (
          <Text className={"text-secondary"}>{row.row.original.department.title}</Text>
        ),
        accessorKey: "department",
        size: "auto" as unknown as number,
      },
      {
        header: t("ats:stakeholders"),
        cell: (row) => <EmployeesListCell employees={row.row.original.stakeholders} />,
        accessorKey: "manager",
        size: 150,
      },
      {
        header: t("ats:recruiters"),
        cell: (row) => <EmployeesListCell employees={row.row.original.recruiters} />,
        accessorKey: "recruiter",
        size: 150,
      },
      {
        header: t("ats:candidates"),
        cell: (row) => (
          <Text className={"text-secondary"}>
            {t("plurals:plural_candidates", { count: row.row.original.candidates_count })}
          </Text>
        ),
        accessorKey: "candidates_count",
        size: 150,
      },
      {
        header: t("common:status"),
        cell: (row) => (
          <Text className={"text-secondary"}>
            {JobStatusItems().find((x) => x.id == row.row.original.status)?.title}
          </Text>
        ),
        accessorKey: "status",
        size: 100,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"items-end"} gap={"sm"}>
            <Button variant={"secondary"} onClick={() => handleEditOrCreate(cell.row.original)}>
              {t("common:edit")}
            </Button>
          </Stack>
        ),
        size: 70,
      }),
    ],
    [jobs]
  );

  const handleEditOrCreate = useCallback((job: ApiJob | undefined = undefined) => {
    const jobToEdit = job || ApiJobEmpty;
    modalsService.openModal(JobEditModal, {
      job: jobToEdit,
    });
  }, []);

  const handleChangeTab = useCallback((tab: JobTabsSection) => {
    setActiveTab(tab);
    switch (tab) {
      case JobTabsSection.my:
        setFilter({
          ...ApiJobsFilterDefault,
          status_list: [JobStatus.published],
          my_jobs: true,
        });
        break;
      case JobTabsSection.opened:
        setFilter({ ...ApiJobsFilterDefault, status_list: [JobStatus.published] });
        break;
      case JobTabsSection.all:
        setFilter(ApiJobsFilterDefault);
        break;
    }
  }, []);

  return (
    <>
      <Page>
        <Page.Header title={t("ats:jobs")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("common:create_new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Tabs.List>
            <Tabs.Button
              onClick={() => handleChangeTab(JobTabsSection.my)}
              isStretch
              isActive={activeTab == JobTabsSection.my}
              title={t("ats:my_jobs")}
            />
            <Tabs.Button
              onClick={() => handleChangeTab(JobTabsSection.opened)}
              isStretch
              isActive={activeTab == JobTabsSection.opened}
              title={t("ats:my_active_jobs")}
            />
            <Tabs.Button
              onClick={() => handleChangeTab(JobTabsSection.all)}
              isStretch
              isActive={activeTab == JobTabsSection.all}
              title={t("ats:all_jobs")}
            />
          </Tabs.List>
          <Stack direction={"horizontal"}>
            <Search
              onInput={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
              type={"text"}
              value={searchTerm}
              placeholder={t("common:search")}
              className={"w-96"}
            />
          </Stack>
          <Table
            data={jobs?.items || []}
            columns={columns}
            showSkeleton={isLoading}
            isFetching={isLoading || isFetching}
            showNotFoundError={filter.text != "" && jobs && jobs.metadata.total_items == 0}
            notFoundComponent={<EmptyStateNothingFound />}
            emptyStateComponent={<EmptyState />}
          />
          <PaginationBlock
            metadata={jobs?.metadata}
            onPageChange={(page) => {
              setFilter({ ...filter, page_number: page });
            }}
          />
        </Page.Content>
      </Page>
    </>
  );
}
