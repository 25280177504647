import Box from "../../../../ui/box/Box.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import { useCallback, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { ApplicantEditModal } from "../widgets/ApplicantEditModal.tsx";
import { ApplicantProfileContacts } from "./ApplicantProfileContacts.tsx";
import { Tabs } from "../../../../ui/tabs/Tabs.tsx";
import { TabItem } from "../../../../ui/tabs/TabItem.tsx";
import { getSourceTitle } from "../../../../types/recruit/source.types.ts";
import ApplicantProfileCV from "./ApplicantProfileCV.tsx";
import { ApplicantProfileComments } from "./ApplicantProfileComments.tsx";
import { useAppTheme } from "../../../../themes/provider.tsx";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";
import { PermissionType } from "../../../../types/role.types.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import ColorBlock from "../../../../ui/colorblock/ColorBlock.tsx";
import { Tag } from "../../../../components/tag/Tag.tsx";
import { ApiLabel } from "../../../../types/recruit/label.types.ts";
import { useNavigate } from "react-router-dom";
import { ApplicantsMergeModal } from "../widgets/ApplicantsMergeModal.tsx";
import { ImageUploadModal } from "../../../../components/avatar-upload/ImageUploadModal.tsx";
import { ApiFilePathTypes } from "../../../../types/file.types.ts";
import { ApiFile } from "../../../../types/common.types.ts";
import {
  useApplicantDelete,
  useApplicantDeletePhoto,
  useApplicantSetPhoto,
  useApplicantsUpdateLabels,
} from "../../../../queries/recruit/use-applicants.query.ts";
import { useLabels } from "../../../../queries/use-labels.query.ts";
import { ApiApplicant, ApiApplicantShort } from "../../../../types/recruit/applicant.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import ButtonIcon from "../../../../ui/button/ButtonIcon.tsx";

export interface ApplicantProfileProps {
  applicant: ApiApplicantShort | undefined;
  applicantFull: ApiApplicant | undefined;
}

export const ApplicantProfile = ({ applicant, applicantFull }: ApplicantProfileProps) => {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { employee } = useEmployeeStore();
  const { data: labels } = useLabels(
    (employee && employee.permissions.manage_candidates == PermissionType.edit) || false
  );
  const navigate = useNavigate();

  const applicantSetPhoto = useApplicantSetPhoto();
  const applicantDeletePhoto = useApplicantDeletePhoto();
  const applicantDelete = useApplicantDelete();
  const applicantUpdateLabels = useApplicantsUpdateLabels();

  const handleEditApplicant = useCallback(() => {
    if (!applicantFull) return;

    modalsService.openModal(ApplicantEditModal, {
      applicant: applicantFull,
    });
  }, [applicantFull]);

  const body = useAppTheme("body");
  const canDeleteCandidate: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.delete_candidates == PermissionType.edit;
  }, [employee]);
  const canManageCandidate: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.manage_candidates == PermissionType.edit;
  }, [employee]);

  const handleToggleLabel = useCallback(
    async (label: ApiLabel) => {
      if (!applicantFull) return;

      const currentLabelIds = applicantFull.labels.map((labelId) => labelId.id);
      const newLabelIds = currentLabelIds.find((x) => x == label.id)
        ? currentLabelIds.filter((x) => x != label.id)
        : [...currentLabelIds, label.id];

      applicantUpdateLabels.mutate(
        {
          applicantId: applicantFull.id,
          labelIds: newLabelIds,
        },
        {
          onSuccess: () => {
            analyticsService.trackEvent(analyticEvents.applicants.labelsEdited, {
              [analyticProperties.id]: applicantFull.id,
              [analyticProperties.title]: label.title,
            });
          },
        }
      );
    },
    [applicantFull]
  );

  const handleMerge = useCallback(async () => {
    if (!applicantFull || applicantFull.similar_applicants.length == 0) return;

    modalsService.openModal(ApplicantsMergeModal, {
      applicant: applicantFull,
    });
  }, [applicant, applicantFull]);

  const handleDelete = useCallback(async () => {
    if (!applicant) return;

    modalsService.openModal(ConfirmationPopup, {
      question: t("common:delete"),
      text: t("ats:delete_candidate_confirm_description"),
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        try {
          await applicantDelete.mutateAsync(applicant.id);
          analyticsService.trackEvent(analyticEvents.applicants.deleted, {
            [analyticProperties.id]: applicant.id,
          });
          closeProfile();
        } catch {
          return;
        }
      },
    });
  }, [applicant]);

  const closeProfile = () => {
    navigate(location.pathname.replace(/\/\d+(?=\/|$|\?)/, "") + location.search);
  };

  const handleAvatarUpload = () => {
    if (!applicant) return;
    modalsService.openModal(ImageUploadModal, {
      modalTitle: t("common:photo"),
      path: ApiFilePathTypes.employee,
      onSave: async (file: ApiFile) => {
        try {
          await applicantSetPhoto.mutateAsync({
            id: applicant.id,
            fileId: file.id,
          });
          analyticsService.trackEvent(analyticEvents.applicants.edited, {
            [analyticProperties.id]: applicant.id,
            [analyticProperties.actionType]: "Photo Deleted",
          });
          return true;
        } catch {
          return false;
        }
      },
    });
  };

  const handleAvatarDelete = async () => {
    if (!applicant) return;

    try {
      await applicantDeletePhoto.mutateAsync(applicant.id);
      analyticsService.trackEvent(analyticEvents.organization.settingsEdited, {
        [analyticProperties.id]: applicant.id,
        [analyticProperties.actionType]: "Photo Deleted",
      });
    } catch {
      return;
    }
  };

  return (
    <>
      {applicant && (
        <Stack>
          <Box className={twMerge("w-full h-40 transition-all relative", body.base)}>
            <Box className={"absolute bg-light/90 inset-0"}>
              <Stack
                direction={"horizontal"}
                justify={"between"}
                items={"center"}
                gap={"sm"}
                className={"px-6 py-4 w-full h-16"}
              >
                <Button
                  onClick={closeProfile}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-close"} />}
                  variant={"secondary"}
                />
                <Stack
                  direction={"horizontal"}
                  items={"center"}
                  gap={"sm"}
                  className={"overflow-hidden"}
                >
                  {canManageCandidate && (
                    <ButtonIcon
                      onClick={handleEditApplicant}
                      variant={"secondary"}
                      icon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square text-lg"} />}
                    >
                      {t("common:edit")}
                    </ButtonIcon>
                  )}
                  {canDeleteCandidate && (
                    <DropdownMenu>
                      <Button
                        onClick={handleDelete}
                        leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                        variant={"menu"}
                      >
                        {t("common:delete")}
                      </Button>
                    </DropdownMenu>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Box>

          <Stack gap={"md"} className={"-mt-28 top-16 w-full px-6 pt-lg pb-xl"} items={"center"}>
            {canManageCandidate && (
              <DropdownMenu
                trigger={
                  <Box className={"relative w-40 h-40 group cursor-pointer "}>
                    <Avatar
                      size={"2xl"}
                      url={applicant.photo_url}
                      variant={"opaque"}
                      acronym={`${applicant.first_name} ${applicant.last_name}`}
                    />
                    <Box
                      className={
                        "bg-dark/20 opacity-0 group-hover:opacity-100 absolute inset-0 rounded-2xl flex items-center justify-center"
                      }
                    >
                      <FontAwesomeIcon
                        icon={"fa-light fa-camera"}
                        className={"text-[40px] text-light "}
                      />
                    </Box>
                  </Box>
                }
              >
                <Stack gap={"sm"}>
                  <Button
                    variant={"menu"}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-image"} />}
                    onClick={handleAvatarUpload}
                  >
                    {t("common:upload_new")}
                  </Button>
                  <Button
                    variant={"menu"}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                    className={"text-danger"}
                    onClick={handleAvatarDelete}
                  >
                    {t("common:delete")}
                  </Button>
                </Stack>
              </DropdownMenu>
            )}
            {!canManageCandidate && (
              <Box className={"relative"}>
                <Avatar
                  size={"2xl"}
                  url={applicant.photo_url}
                  variant={"opaque"}
                  acronym={`${applicant.first_name} ${applicant.last_name}`}
                />
              </Box>
            )}

            <Stack items={"center"} className={"text-lg"}>
              <Title
                header={`${applicant.first_name} ${applicant.middle_name} ${applicant.last_name}`}
                size={"lg"}
              />
              {applicant.current_position.position && (
                <Text className={"text-secondary"}>{applicant.current_position.position}</Text>
              )}
              {applicant.current_position.company && (
                <Text className={"text-secondary"}>{applicant.current_position.company}</Text>
              )}
            </Stack>
            {applicantFull && (
              <Box>
                <Stack direction={"horizontal"} gap={"sm"}>
                  {applicantFull.labels.map((label, index) => (
                    <Tag color={label.color} key={`applicant-tag-${index}`}>
                      {label.title}
                    </Tag>
                  ))}
                  {canManageCandidate && (
                    <DropdownMenu
                      trigger={
                        <Button
                          leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
                          variant={"secondary"}
                        >
                          {applicantFull.labels.length == 0 ? t("ats:tag_add") : ""}
                        </Button>
                      }
                    >
                      {labels?.map((label) => (
                        <Button
                          key={`label-${label.id}`}
                          variant={"menu"}
                          leftIcon={<ColorBlock color={label.color} />}
                          onClick={() => {
                            handleToggleLabel(label);
                          }}
                        >
                          {label.title}
                        </Button>
                      ))}
                    </DropdownMenu>
                  )}
                </Stack>
              </Box>
            )}
          </Stack>
          {applicantFull && applicantFull.similar_applicants.length > 0 && (
            <Stack className={"border-b border-t border-dark/5 p-6"} gap={"sm"}>
              <Avatar url={applicantFull.similar_applicants[0].photo_url} size={"md"} />
              <Stack>
                <Text className={"text-warning font-semibold"}>
                  {t("ats:similar_candidate_found_title")}
                </Text>
                <Text className={"text-secondary"}>
                  {t("ats:similar_candidate_found_description")}
                </Text>
              </Stack>
              <Box>
                <Button onClick={handleMerge}>{t("ats:review")}</Button>
              </Box>
            </Stack>
          )}

          <Stack className={"px-6 pb-6"}>
            <ApplicantProfileContacts
              applicant={applicant}
              contacts={applicant.contacts}
              applicantFull={applicantFull}
            />
            {/*<Title header={t("ats:experience")} paddingTop paddingBottom />*/}
            <Tabs className={"pt-xl"}>
              {applicantFull &&
                applicantFull.cvs?.map((cv, index) => (
                  <TabItem title={getSourceTitle(cv.source)} key={`resume-tab-${index}`}>
                    <ApplicantProfileCV applicant={applicantFull} cv={cv} />
                  </TabItem>
                ))}
              <TabItem title={t("core:notes")}>
                {applicantFull && <ApplicantProfileComments applicant={applicantFull} />}
              </TabItem>
            </Tabs>
          </Stack>
        </Stack>
      )}
    </>
  );
};
