import { useTranslation } from "react-i18next";
import ButtonLink from "../../ui/button/ButtonLink.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import Text from "../../ui/typography/Text.tsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip/Tooltip.tsx";
import { ContactTypes } from "../../types/common.types.ts";

interface ContactButtonProps {
  type: ContactTypes;
  contact: string;
}

const contactConfig = {
  telegram: {
    icon: "fa-brands fa-telegram",
    hoverColor: "hover:text-[#0088cc]",
    getUrl: (contact: string) => {
      return `https://t.me/+${contact.replace(/\D/g, "")}`;
    },
    contactName: "Telegram",
  },
  whatsapp: {
    icon: "fa-brands fa-whatsapp",
    hoverColor: "hover:text-[#0E9042]",
    getUrl: (contact: string) => {
      return `https://wa.me/${contact.replace(/\D/g, "")}`;
    },
    contactName: "WhatsApp",
  },
  viber: {
    icon: "fa-brands fa-viber",
    hoverColor: "hover:text-[#AB2C78]",
    getUrl: (contact: string) => {
      const cleanNumber = contact.replace(/\D/g, "");
      if (cleanNumber.startsWith("1") || cleanNumber.length > 10) {
        // If it has a country code, prepend with +
        return `viber://chat/?number=%2B${cleanNumber}`;
      } else {
        // If it's just a local number, prepend with 2B
        return `viber://chat/?number=2B${cleanNumber}`;
      }
    },
    contactName: "Viber",
  },
};

export const ContactButton = ({ type, contact }: ContactButtonProps) => {
  const { t } = useTranslation();
  const config = contactConfig[type];

  return (
    <Tooltip delay={false}>
      <TooltipTrigger>
        <ButtonLink variant="secondary" href={config.getUrl(contact)} size="sm" blank>
          <FontAwesomeIcon
            className={`text-icon-16 transition-all duration-300 ${config.hoverColor} hover:bg-dark/10 p-2 rounded-lg`}
            icon={config.icon}
          />
        </ButtonLink>
      </TooltipTrigger>
      <TooltipContent>
        <Text className="text-white">{`${t("common:chat_in")} ${config.contactName}`}</Text>
      </TooltipContent>
    </Tooltip>
  );
};

export default ContactButton;
