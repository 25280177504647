import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SelectItem } from "../ui/select/select.types.ts";

export default function useThemeOptions() {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      { id: null, title: t("Default") },
      { id: "neo", title: t("Hoth") },
      { id: "skyline", title: t("CMYK") },
      { id: "max", title: t("MAX") },
      { id: "wave", title: t("Wave") },
    ] as SelectItem[];
  }, [t]);
}
