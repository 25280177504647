import { api } from "./axios";
import { ApiPagedData } from "../types/filter.types.ts";
import { ApiNotification, ApiNotificationsFilter } from "../types/notification.types.ts";

export const apiNotifications = {
  searchNotifications: (filter: ApiNotificationsFilter, signal: AbortSignal) => {
    return api.post<ApiPagedData<ApiNotification>>(`/api/v1/notifications/search`, filter, signal);
  },
  /*
   * Можем вызывать без query как действие сделал и забыл
   * */
  markAsRead: (id: number, archive?: boolean) => {
    return api.post(`/api/v1/notifications/${id}/read`, { archive });
  },
};
