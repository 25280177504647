import { SelectItem } from "../ui/select/select.types";
import { BirthdayDisplayType, EmployeeFullNameRenderType } from "./employees/employee.types.ts";
import { t } from "i18next";

export enum Languages {
  english = "en",
  russian = "ru",
}

export enum EmployeeDatesFormat {
  yyyy_mm_dd = "yyyy_mm_dd",
  mm_dd_yyyy = "mm_dd_yyyy",
  dd_mm_yyyy = "dd_mm_yyyy",
  dd_mm_yyyy_dot = "dd.mm.yyyy",
}

export interface LanguageItem extends SelectItem {}

export interface NameFormatItem extends SelectItem {}

export interface DateFormatItem extends SelectItem {}

export interface BirthdayDisplayFormatItem extends SelectItem {}

export interface ApiOrganizationSettings {
  title: string;
  language?: Languages;
  timezone?: string;
  employee_name_format?: EmployeeFullNameRenderType;
  default_date_format?: EmployeeDatesFormat;
  logo_url?: string;
  background_url?: string;
  theme?: string;
  birthday_display?: BirthdayDisplayType;
}

export const LanguageItems: LanguageItem[] = [
  {
    id: Languages.english,
    title: "common:english",
  },
  {
    id: Languages.russian,
    title: "common:russian",
  },
];

export const NameFormats: NameFormatItem[] = [
  {
    id: EmployeeFullNameRenderType.first_last,
    title: "common:first_last",
  },
  {
    id: EmployeeFullNameRenderType.last_first,
    title: "common:last_first",
  },
  {
    id: EmployeeFullNameRenderType.f_last,
    title: "common:f_last",
  },
];

export const DateFormats: DateFormatItem[] = [
  {
    id: EmployeeDatesFormat.dd_mm_yyyy,
    title: "common:dd_mm_yyyy",
  },
  {
    id: EmployeeDatesFormat.mm_dd_yyyy,
    title: "common:mm_dd_yyyy",
  },
  {
    id: EmployeeDatesFormat.yyyy_mm_dd,
    title: "common:yyyy_mm_dd",
  },
  {
    id: EmployeeDatesFormat.dd_mm_yyyy_dot,
    title: "common:dd_mm_yyyy_dot",
  },
];

export const BirthdayDisplayFormats: BirthdayDisplayFormatItem[] = [
  {
    id: BirthdayDisplayType.full,
    title: "core:birthday_display_full",
  },
  {
    id: BirthdayDisplayType.short,
    title: "core:birthday_display_short",
  },
  {
    id: BirthdayDisplayType.none,
    title: "core:birthday_display_none",
  },
];

export interface ApiOrganizationSettingsSavePayload extends ApiOrganizationSettings {}

export const LanguageOptions = LanguageItems.map((item) => {
  return {
    id: item.id,
    title: t(item.title),
  };
});
export const NameFormatOptions = NameFormats.map((item) => {
  return {
    id: item.id,
    title: t(item.title),
  };
});
export const DateFormatOptions = DateFormats.map((item) => {
  return {
    id: item.id,
    title: t(item.title),
  };
});
export const BirthdayDisplayOptions = BirthdayDisplayFormats.map((item) => {
  return {
    id: item.id,
    title: t(item.title),
  };
});
