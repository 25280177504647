import { Permissions, PermissionType } from "../../../types/role.types.ts";
import i18n from "../../../i18n.ts";
import { ApiMeEmployee } from "../../../types/employees/employee.types.ts";

export interface SettingGroup {
  title: string;
  // show: boolean;
  items: SettingItem[];
}

interface SettingItem {
  title: string;
  description: string;
  to: string;
  icon: string;
  permission: Permissions;
  menuTitle: string;
}

export const SettingsConfig = () => {
  return [
    {
      title: i18n.t("common:general"),
      items: [
        {
          title: i18n.t("core:organization"),
          menuTitle: i18n.t("core:organization"),
          description: i18n.t("core:settings_organization_description"),
          to: "/settings/organization",
          permission: Permissions.settings_organization,
          icon: "fa-gear",
        },
        {
          title: i18n.t("core:divisions"),
          menuTitle: i18n.t("core:divisions"),
          description: i18n.t("core:settings_division_description"),
          to: "/settings/divisions",
          permission: Permissions.settings_divisions,
          icon: "fa-folder-tree",
        },
        {
          title: i18n.t("core:departments"),
          menuTitle: i18n.t("core:departments"),
          description: i18n.t("core:settings_departments_description"),
          to: "/settings/departments",
          permission: Permissions.settings_departments,
          icon: "fa-list-tree",
        },
        {
          title: i18n.t("core:positions"),
          menuTitle: i18n.t("core:positions"),
          description: i18n.t("core:settings_positions_description"),
          to: "/settings/positions",
          permission: Permissions.settings_positions_levels,
          icon: "fa-circle-user",
        },
        {
          title: i18n.t("common:locations"),
          menuTitle: i18n.t("common:locations"),
          description: i18n.t("core:permissions_manage_locations_description"),
          to: "/settings/locations",
          permission: Permissions.settings_locations,
          icon: "fa-location-dot",
        },
        {
          title: i18n.t("core:legal_entities"),
          menuTitle: i18n.t("core:legal_entities"),
          description: i18n.t("core:settings_legal_entities_description"),
          to: "/settings/legal-entities",
          permission: Permissions.settings_legal_entities,
          icon: "fa-building",
        },
        {
          title: i18n.t("core:termination_reasons"),
          menuTitle: i18n.t("core:termination_reasons"),
          description: i18n.t("core:settings_termination_reasons_description"),
          to: "/settings/dismissal-reasons",
          permission: Permissions.dismiss_employees,
          icon: "fa-location-dot",
        },
        // {
        //   title: i18n.t("integrations:settings_integrations_title"),
        //   menuTitle: i18n.t("integrations:settings_integrations_title"),
        //   description: i18n.t("integrations:settings_integrations_description"),
        //   to: "/settings/integrations",
        //   permission: Permissions.settings_organization,
        //   icon: "fa-gears",
        // },
      ],
    },
    {
      title: i18n.t("ats:recruit"),

      items: [
        {
          title: i18n.t("ats:pipelines"),
          menuTitle: i18n.t("ats:pipelines"),
          description: i18n.t("core:settings_pipelines_description"),
          to: "/settings/pipelines",
          permission: Permissions.settings_pipelines,
          icon: "fa-timeline",
        },
        {
          title: i18n.t("ats:sources"),
          menuTitle: i18n.t("ats:sources"),
          description: i18n.t("core:settings_sources_description"),
          to: "/settings/sources",
          permission: Permissions.settings_sources,

          icon: "fa-file-import",
        },
        {
          title: i18n.t("ats:tags"),
          menuTitle: i18n.t("ats:tags"),
          description: i18n.t("core:settings_tags_description"),
          to: "/settings/labels",
          permission: Permissions.manage_candidates,

          icon: "fa-tags",
        },
        {
          title: i18n.t("ats:disqualification_reasons"),
          menuTitle: i18n.t("ats:disqualification"),
          description: i18n.t("core:settings_disqualification_reasons_description"),
          to: "/settings/disqualification-reasons",
          permission: Permissions.settings_candidate_disqualification_reasons,

          icon: "fa-user-xmark",
        },
        {
          title: i18n.t("core:email_templates"),
          menuTitle: i18n.t("core:email_templates"),
          description: i18n.t("core:settings_email_templates_description"),
          to: "/settings/email-templates",
          permission: Permissions.settings_email_templates,

          icon: "fa-envelopes",
        },
      ],
    },
    {
      title: i18n.t("common:security"),

      items: [
        {
          title: i18n.t("core:roles_and_permissions"),
          menuTitle: i18n.t("core:roles_and_permissions"),
          description: i18n.t("core:settings_roles_and_permissions_description"),
          to: "/settings/roles-and-permissions",
          permission: Permissions.settings_roles_permissions,

          icon: "fa-shield-check",
        },
        {
          title: i18n.t("data_protection:data_protection"),
          menuTitle: i18n.t("data_protection:data_protection"),
          description: i18n.t("core:settings_data_protection_description"),
          to: "/settings/data-protection",
          permission: Permissions.settings_data_protection,

          icon: "fa-shield-check",
        },
      ],
    },
  ] as SettingGroup[];
};

export const getSettingsVisibleToEmployee = (employee: ApiMeEmployee): SettingGroup[] => {
  const result: SettingGroup[] = [];
  const settingsConfig = SettingsConfig();
  for (let groupIndex = 0; groupIndex < settingsConfig.length; groupIndex++) {
    const showItems: SettingItem[] = [];

    for (let itemIndex = 0; itemIndex < settingsConfig[groupIndex].items.length; itemIndex++) {
      const show =
        employee.permissions[settingsConfig[groupIndex].items[itemIndex].permission] ==
        PermissionType.edit;

      if (show) showItems.push(settingsConfig[groupIndex].items[itemIndex]);
    }

    if (showItems.length > 0) {
      result.push({
        title: settingsConfig[groupIndex].title,
        items: showItems,
      });
    }
  }
  return result;
};
