import { api } from "./axios.ts";
import { ApiCandidate, ApiCandidateShort } from "../types/recruit/applicant.types";
import { ApiCandidateCreatePayload } from "../types/recruit/candidate.types.ts";
import { ApiOperationResult } from "../types/common.types.ts";

export const apiCandidates = {
  create: (payload: ApiCandidateCreatePayload) => {
    return api.post<ApiCandidateShort>(`/api/v1/candidates`, payload);
  },
  getByApplicantId: (applicantId: number) => {
    return api.get<ApiCandidate[]>(`/api/v1/candidates/by-applicant/${applicantId}`);
  },
  delete: (id: number) => {
    return api.delete<ApiOperationResult>(`/api/v1/candidates/${id}`);
  },
  snooze: (id: number) => {
    return api.put<ApiOperationResult>(`/api/v1/candidates/${id}/snooze`, {});
  },
};
