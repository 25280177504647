import { useMemo } from "react";
import { ColumnDef, createColumnHelper, DisplayColumnDef } from "@tanstack/react-table";
import Text from "../../../../ui/typography/Text.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Box from "../../../../ui/box/Box.tsx";
import {
  ApiAnalyticsDisqualificationReasonsReportRowItem,
  ApiAnalyticsRecruitingDisqualificationReasonsReport,
} from "../../../../types/analytics/analytics.recruiting.types.ts";
import { useRecruitingReports } from "../recruiting-reports.hook.ts";
import { useTranslation } from "react-i18next";
import Table from "../../../../ui/table/Table.tsx";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import { ApiPipelineStage } from "../../../../types/recruit/stages.types.ts";
import LinkButtonTo from "../../../../ui/link/LinkButtonTo.tsx";

export interface DisqualificationReasonsReportTableProps {
  report: ApiAnalyticsRecruitingDisqualificationReasonsReport | undefined;
  isLoading: boolean;
}

export interface VMDisqualificationReasonsReportRowItem {
  item: ApiAnalyticsDisqualificationReasonsReportRowItem;
  width: number;
}

export interface VMDisqualificationReasonsReportRow {
  reasons: VMDisqualificationReasonsReportRowItem[];
  pipeline_stage: ApiPipelineStage;
  total: number;
  width: number;
  applicants_ids: number[];
}

export const DisqualificationReasonsReportTable = ({
  report,
  isLoading,
}: DisqualificationReasonsReportTableProps) => {
  const { handlePreviewApplicants } = useRecruitingReports();
  const { t } = useTranslation();

  const calculatedReportRows = useMemo(() => {
    if (!report) return [];

    const rows: VMDisqualificationReasonsReportRow[] = [];
    const dayNumbers = report.report.rows.flatMap((e) => e.reasons).map((item) => item.value);
    if (dayNumbers.length == 0) return [];

    const sums = report.report.rows.map((row) =>
      row.reasons.reduce((sum, source) => sum + source.value, 0)
    );
    const maxSumPerRows = Math.max(...sums, 1);
    const widthPerDay = Math.round(70 / maxSumPerRows);

    for (let i = 0; i < report.report.rows.length; i++) {
      const row = report.report.rows[i];

      const rowItems: VMDisqualificationReasonsReportRowItem[] = [];

      for (let j = 0; j < report.config.reasons.length; j++) {
        const disqualificationReasonsConfig = report.config.reasons[j];
        const disqualificationReasonsItem = row.reasons.find(
          (e) => e.reason.id == disqualificationReasonsConfig.id
        );
        if (disqualificationReasonsItem) {
          rowItems.push({
            item: disqualificationReasonsItem,
            width:
              disqualificationReasonsItem.value > 0
                ? Math.round(disqualificationReasonsItem.value * widthPerDay) + 10
                : 2,
          });
        }
      }

      const total = row.reasons.reduce((acc, item) => acc + item.value, 0);
      const applicantIds = row.reasons.flatMap((e) => e.applicants_ids);
      rows.push({
        reasons: rowItems,
        pipeline_stage: row.pipeline_stage,
        total: total,
        width: total > 0 ? Math.round(total * widthPerDay) + 10 : 2,
        applicants_ids: applicantIds,
      });
    }

    return rows;
  }, [report]);

  const columnHelper = createColumnHelper<VMDisqualificationReasonsReportRow>();
  const columns = useMemo<ColumnDef<VMDisqualificationReasonsReportRow>[]>(() => {
    const inner: DisplayColumnDef<VMDisqualificationReasonsReportRow, unknown>[] = [];
    inner.push(
      columnHelper.display({
        id: "pipeline_stage",
        header: t(""),
        cell: (cell) => (
          <Text className={"text-default"}>{cell.row.original.pipeline_stage.title}</Text>
        ),
        size: 300,
      })
    );

    inner.push(
      columnHelper.display({
        id: `stage-total`,
        header: () => <Text>{t("common:total")}</Text>,
        cell: (cell) => (
          <>
            {cell.row.original.total > 0 && (
              <Stack direction={"horizontal"} gap={"sm"}>
                <Box
                  style={{ width: cell.row.original.width }}
                  className={"bg-brand-dark h-5 rounded-r-md"}
                ></Box>
                <LinkButtonTo
                  variant={"secondary"}
                  className={cell.row.original.total > 0 ? "text-default" : undefined}
                  onClick={() => {
                    handlePreviewApplicants(
                      cell.row.original.applicants_ids,
                      AnalyticReportTypes.recruitingDisqualificationReasons
                    );
                  }}
                >
                  {cell.row.original.total}
                </LinkButtonTo>
              </Stack>
            )}
          </>
        ),
        enableResizing: false,
        size: "auto" as unknown as number,
      })
    );

    if (report?.config && report.config.reasons.length > 0) {
      for (let i = 0; i < report.config.reasons.length; i++) {
        const disqualificationReason = report.config.reasons[i];
        inner.push(
          columnHelper.display({
            id: `stage-${i}`,
            header: () => <Text className={"capitalize"}>{disqualificationReason.title}</Text>,
            cell: (cell) => (
              <>
                {cell.row.original.reasons[i].item.value > 0 ? (
                  <Stack direction={"horizontal"} gap={"sm"}>
                    <Box
                      style={{ width: cell.row.original.reasons[i].width }}
                      className={"bg-brand-dark/80 h-5 rounded-r-md"}
                    ></Box>
                    <LinkButtonTo
                      variant={"secondary"}
                      className={"text-default"}
                      onClick={() => {
                        handlePreviewApplicants(
                          cell.row.original.reasons[i].item?.applicants_ids || [],
                          AnalyticReportTypes.recruitingDisqualificationReasons
                        );
                      }}
                    >
                      {cell.row.original.reasons[i].item?.value}
                    </LinkButtonTo>
                  </Stack>
                ) : (
                  <Stack direction={"horizontal"} gap={"sm"}>
                    <Box style={{ width: 2 }} className={"bg-brand-dark/80 h-5 rounded-r-md"}></Box>
                    <Text className={"text-secondary"}>-</Text>
                  </Stack>
                )}
              </>
            ),
            enableResizing: false,
            size: "auto" as unknown as number,
          })
        );
      }
    }
    return inner;
  }, [report, calculatedReportRows]);

  return <Table data={calculatedReportRows || []} columns={columns} showSkeleton={isLoading} />;
};
