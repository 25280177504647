import { ApiAnalyticsAggregationDate } from "../../../../types/analytics/analytics.recruiting.types.ts";
import Button from "../../../../ui/button/Button.tsx";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import { useCallback, useMemo } from "react";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { SelectOption } from "../../../../ui/select/SelectOption.tsx";

import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { ChoosePeriodModal } from "../widgets/ChoosePeriodModal.tsx";
import { useTranslation } from "react-i18next";
import useDatePeriods from "../../../../hooks/use-date-periods.hook.ts";
import { useSearchParams } from "react-router-dom";
import { parseUrlHelper } from "../../../../helpers/parse-url.helper.ts";

export interface ButtonChoosePeriodProps {
  aggregationType: ApiAnalyticsAggregationDate;
  dateFrom?: Date;
  dateTo?: Date;
}

export const RecruitingReportingButtonChoosePeriod = ({
  aggregationType,
  dateFrom,
  dateTo,
}: ButtonChoosePeriodProps) => {
  const { formatPeriodInReporting } = useFormatter();
  const { t } = useTranslation();
  const modalsService = useModals();
  const [, setSearchParams] = useSearchParams();

  const { getAvailableOptions } = useDatePeriods();

  const availableOptions = useMemo(() => {
    return getAvailableOptions(aggregationType);
  }, [aggregationType]);

  const selectedOption = useMemo(() => {
    return availableOptions.find(
      (option) =>
        option.start.getTime() == dateFrom?.getTime() && option.end.getTime() == dateTo?.getTime()
    );
  }, [aggregationType, dateFrom, dateTo]);

  const handleCustomPeriod = useCallback(() => {
    modalsService.openModal(ChoosePeriodModal, {
      dateFrom,
      dateTo,
      onApply: (dfrom: Date, dto: Date) => {
        setSearchParams((searchParams) => {
          searchParams.set("date_start", parseUrlHelper.formatDate(dfrom));
          searchParams.set("date_end", parseUrlHelper.formatDate(dto));
          searchParams.delete("period");
          return searchParams;
        });
      },
    });
  }, [dateFrom, dateTo]);
  return (
    <DropdownMenu
      trigger={
        <Button
          variant={"secondary"}
          rightIcon={<FontAwesomeIcon icon={"fa-light fa-chevron-down"} />}
        >
          {selectedOption
            ? selectedOption.title
            : formatPeriodInReporting(dateFrom, dateTo, aggregationType)}
        </Button>
      }
    >
      {availableOptions.map((option) => (
        <SelectOption
          key={`choose-period-${option.title} `}
          item={{
            id: option.title,
            title: option.title,
            selected:
              option.start.getTime() == dateFrom?.getTime() &&
              option.end.getTime() == dateTo?.getTime(),
            item: option,
          }}
          isMulti={false}
          handleSelect={() => {
            setSearchParams((searchParams) => {
              searchParams.delete("date_start");
              searchParams.delete("date_end");
              searchParams.set("period", option.key);
              return searchParams;
            });
          }}
        />
      ))}
      <SelectOption
        item={{
          id: "custom",
          title: t("common:custom_period"),
          selected: !selectedOption,
          item: "custom",
        }}
        isMulti={false}
        handleSelect={handleCustomPeriod}
      />
    </DropdownMenu>
  );
};
