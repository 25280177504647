import Modal from "../../../../../ui/modal/modal.tsx";
import { EditRuleSchema, EditRuleSchemaObject, RuleEditModalProps } from "../../role.types.ts";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "../../../../../ui/select/Select.tsx";
import { RoleSignItems } from "../role-edit.types.ts";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import useModals from "../../../../../ui/modal/modal.store.ts";
import Multiselect from "../../../../../ui/select/Multiselect.tsx";
import { SelectItem, SelectValueType } from "../../../../../ui/select/select.types.ts";
import { useEffect, useMemo } from "react";
import { RoleApplySignType, RoleAssignToConditionType } from "../../../../../types/role.types.ts";
import FontAwesomeIcon from "../../../../../ui/typography/FontAwesomeIcon.tsx";
import { useDivisionsSearchInline } from "../../../../../queries/use-divisitions.query.ts";
import { useDepartmentsSearchInline } from "../../../../../queries/use-departments.query.ts";
import { usePositionsSearchInline } from "../../../../../queries/use-positions.query.ts";
import useEmployeeSearchInline from "../../../../../queries/employees/use-employees.query.ts";
import { useLocationsSearchInline } from "../../../../../queries/use-locations.query.ts";
import { useLegalEntitiesSearchInline } from "../../../../../queries/use-legal-entities.query.ts";

export function RuleEditModal({
  rule,
  onModified,
  onDelete,
  conditionTypes,
  ...props
}: RuleEditModalProps) {
  const { t } = useTranslation();
  const { close } = useModals();
  const { id } = { ...props };

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    watch,
    setValue,
  } = useForm<EditRuleSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EditRuleSchemaObject),
    defaultValues: {
      condition_ids: rule?.condition_ids ?? [],
      sign: RoleApplySignType.include,
      condition_type: rule?.condition_type ?? RoleAssignToConditionType.employee,
      condition_entities: rule?.condition_entities ?? [],
    },
  });

  const selectedConditionType = useWatch({ control, name: "condition_type" });

  const divisions = useDivisionsSearchInline(
    selectedConditionType === RoleAssignToConditionType.division
  );
  const departments = useDepartmentsSearchInline(
    selectedConditionType === RoleAssignToConditionType.department
  );

  const positions = usePositionsSearchInline(
    selectedConditionType === RoleAssignToConditionType.position
  );

  const employee = useEmployeeSearchInline(
    selectedConditionType === RoleAssignToConditionType.employee
  );

  const locations = useLocationsSearchInline(
    selectedConditionType == RoleAssignToConditionType.location
  );
  const legalEntities = useLegalEntitiesSearchInline(
    selectedConditionType == RoleAssignToConditionType.legal_entity
  );

  const options = useMemo(() => {
    switch (selectedConditionType) {
      case RoleAssignToConditionType.division:
        return divisions.options;
      case RoleAssignToConditionType.department:
        return departments.options;
      case RoleAssignToConditionType.position:
        return positions.options;
      case RoleAssignToConditionType.employee:
        return employee.options;
      case RoleAssignToConditionType.location:
        return locations.options;
      case RoleAssignToConditionType.legal_entity:
        return legalEntities.options;
      default:
        return [] as SelectItem[];
    }
  }, [
    selectedConditionType,
    divisions.options,
    departments.options,
    positions.options,
    employee.options,
    locations.options,
    legalEntities.options,
  ]);

  const onSubmit = handleSubmit((ruleData) => {
    onModified({
      condition_type: ruleData["condition_type"],
      condition_entities: ruleData["condition_entities"] as SelectItem[],
      condition_ids: ruleData["condition_ids"],
      sign: ruleData["sign"],
    });

    close(id);
  });

  const handleSearch = (text: string | undefined) => {
    switch (selectedConditionType) {
      case RoleAssignToConditionType.division:
        divisions.search(text);
        break;
      case RoleAssignToConditionType.department:
        departments.search(text);
        break;
      case RoleAssignToConditionType.position:
        positions.search(text);
        break;
      case RoleAssignToConditionType.employee:
        employee.search(text);
        break;
      case RoleAssignToConditionType.location:
        locations.search(text);
        break;
      case RoleAssignToConditionType.legal_entity:
        legalEntities.search(text);
        break;
    }
  };

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name === "condition_type" && type == "change") {
        resetField("condition_entities");
        resetField("condition_ids");
        if (rule) rule.condition_entities = [];
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleSelect = (selected: SelectValueType[]) => {
    const selectedOptions = options?.filter((item) => ~selected.indexOf(item.id));

    setValue("condition_entities", selectedOptions ?? []);
  };

  return (
    <Modal
      title={rule ? t("core:edit_rule") : t("core:create_new_rule")}
      withCloser={true}
      {...props}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} size={"lg"} onClick={onSubmit}>
            {t("common:save")}
          </Button>
          <Button
            variant={rule ? "danger" : "plain"}
            size={"lg"}
            leftIcon={rule && <FontAwesomeIcon icon={"fa-light fa-xmark"} />}
            onClick={() => {
              rule && onDelete && onDelete();

              close(id);
            }}
          >
            {rule ? t("common:delete") : t("common:cancel")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Select
              options={conditionTypes}
              emptyTitle={t("common:select")}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          name={`condition_type`}
        />
        <Controller
          render={({ field: { value, onChange } }) => (
            <Select
              options={RoleSignItems()}
              emptyTitle={t("common:select")}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          name={`sign`}
        />
        <Controller
          render={({ field: { value, onChange } }) => (
            <Multiselect
              emptyTitle={t("common:select")}
              values={value}
              onChange={(selected) => {
                handleSelect(selected);
                onChange(selected);
              }}
              searchable
              options={rule?.condition_entities}
              onSearchOptions={handleSearch}
              searchOptions={options}
              loadOptionsOnOpen
            />
          )}
          control={control}
          name={`condition_ids`}
        />
      </form>
    </Modal>
  );
}
