import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { ApiMeEmployee } from "../types/employees/employee.types.ts";

export const analyticEvents = {
  loggedIn: "Logged in",
  passwordChanged: "Password Changed",
  passwordReset: "Password Reset",
  inviteAccepted: "Invite Accepted",
  pageAction: "Page Action",
  dataExported: "Data Exported",
  settings: {
    viewed: "Settings Viewed",
  },
  organization: {
    settingsViewed: "Organization Settings Viewed",
    settingsEdited: "Organization Settings Edited",
  },
  divisions: {
    viewed: "Divisions List Viewed",
    created: "Division Created",
    edited: "Division Edited",
    deleted: "Division Deleted",
  },
  positions: {
    viewed: "Positions List Viewed",
    created: "Position Created",
    edited: "Position Edited",
    deleted: "Position Deleted",
  },
  departments: {
    viewed: "Departments List Viewed",
    viewedChart: "Departments Chart Viewed",
    created: "Department Created",
    edited: "Department Edited",
    deleted: "Department Deleted",
  },
  roles: {
    viewed: "Roles List Viewed",
    viewedEmployees: "Employees List With Roles Viewed",
    created: "Role Created",
    edited: "Role Edited",
    deleted: "Role Deleted",
  },
  employees: {
    viewed: "Employees List Viewed",
    previewViewed: "Employees Preview List Viewed",
    created: "Employee Created",
    edited: "Employee Edited",
    dismissed: "Employee Terminated",
    dismissalDeleted: "Employee Termination Deleted",
    viewedEmployee: "Employee Viewed",
    viewedChart: "Employee Chart Viewed",
    accountSettingsViewed: "Employee Account Settings Viewed",
    accountSettingsEdited: "Employee Account Settings Edited",
  },
  employeePositions: {
    created: "Employee Position Created",
    edited: "Employee Position Edited",
    deleted: "Employee Position Deleted",
  },
  pipelines: {
    viewed: "Pipelines List Viewed",
    created: "Pipeline Created",
    edited: "Pipeline Edited",
    deleted: "Pipeline Deleted",
    stageCreated: "Pipeline Stage Created",
    stageEdited: "Pipeline Stage Edited",
    stageDeleted: "Pipeline Stage Deleted",
    stagesSorted: "Pipeline Stages Sorted",
  },
  sources: {
    viewed: "Sources List Viewed",
    created: "Source Created",
    edited: "Source Edited",
    deleted: "Source Deleted",
  },
  labels: {
    viewed: "Labels List Viewed",
    created: "Label Created",
    edited: "Label Edited",
    deleted: "Label Deleted",
  },
  disqualificationReasons: {
    viewed: "Disqualification Reasons List Viewed",
    created: "Disqualification Reason Created",
    edited: "Disqualification Reason Edited",
    deleted: "Disqualification Reason Deleted",
  },
  jobs: {
    viewed: "Jobs List Viewed",
    created: "Job Created",
    edited: "Job Edited",
  },
  applicants: {
    viewed: "Applicants List Viewed",
    created: "Applicant Created",
    edited: "Applicant Edited",
    viewedApplicant: "Applicant Viewed",
    assignedToJob: "Applicant Assigned To Job",
    addedAction: "Applicant Added Action",
    actionEdited: "Applicant Action Edited",
    actionDeleted: "Applicant Action Deleted",
    cvDownloaded: "Applicant CV Downloaded",
    cvTextEdited: "Applicant CV Text Edited",
    deleted: "Applicant Deleted",
    labelsEdited: "Applicant Labels Edited",
    mergeResolved: "Applicant Merge Resolved",
    filtered: "Applicants Filtered",
  },
  candidates: {
    addedAction: "Candidate Added Action",
    changedStage: "Candidate Changed Stage",
    disqualified: "Candidate Disqualified",
    qualified: "Candidate Qualified",
    deleted: "Candidate Deleted",
    actionDeleted: "Candidate Action Deleted",
    actionEdited: "Candidate Action Edited",
    snoozed: "Candidate Snoozed",
  },
  reporting: {
    viewed: "Report Viewed",
    dataViewed: "Report Data Viewed",
    filtered: "Report Filtered",
  },
  email: {
    sent: "Email Sent",
    disconnected: "Email Disconnected",
    viewed: "Email Viewed",
  },
  calendar: {
    disconnected: "Calendar Disconnected",
  },
  events: {
    calendarViewed: "Calendar Viewed",
    created: "Event Created",
    edited: "Event Edited",
    cancelled: "Event Cancelled",
    viewed: "Event Viewed",
    deleted: "Event Deleted",
    joinedConference: "Event Joined Conference",
  },
  documents: {
    folderCreated: "Documents Folder Created",
    folderEdited: "Documents Folder Edited",
    folderDeleted: "Documents Folder Deleted",
    created: "Document Created",
    edited: "Document Edited",
    moved: "Document Moved",
    deleted: "Document Deleted",
  },
  notes: {
    created: "Note Created",
    edited: "Note Edited",
    deleted: "Note Deleted",
  },
  locations: {
    viewed: "Locations List Viewed",
    created: "Location Created",
    edited: "Location Edited",
    deleted: "Location Deleted",
  },
  legalEntities: {
    viewed: "Legal Entities List Viewed",
    created: "Legal Entity Created",
    edited: "Legal Entity Edited",
    deleted: "Legal Entity Deleted",
  },
  employeeDismissalReasons: {
    viewed: "Termination Reasons List Viewed",
    created: "Termination Reason Created",
    edited: "Termination Reason Edited",
    deleted: "Termination Reason Deleted",
  },
  emailTemplates: {
    viewed: "Email Templates List Viewed",
    created: "Email Template Created",
    edited: "Email Template Edited",
    deleted: "Email Template Deleted",
  },
  home: {
    viewed: "Viewed Home",
  },
  dataProtection: {
    viewed: "Data Protection Viewed",
    settingsEdited: "Data Protection Settings Edited",
    settingsToggled: "Data Protection Settings Toggled",
    requestForOrganizationSent: "Data Protection Request For Organization Sent",
    requestSent: "Data Protection Request Sent",
    responsePageViewed: "Data Protection Response Page Viewed",
    responseReceived: "Data Protection Response Received",
  },
  integrations: {
    viewed: "Integrations Viewed",
    manageViewed: "Integration Manage Viewed",
    edited: "Integration Edited",
  },
};

export const analyticProperties = {
  id: "Id",
  url: "URL",
  source: "Source",
  tab: "Tab",
  actionType: "Action Type",
  title: "Title",
  pipelineStageTitle: "Stage Title",
  reasonTitle: "Reason Title",
  format: "Format",
  count: "Count",
  reserved: "Reserved",
};

class AnalyticsService {
  apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
    this.init();
  }

  public async init() {
    if (import.meta.env.PROD) {
      const sessionReplayTracking = sessionReplayPlugin({
        forceSessionTracking: false,
        sampleRate: 1,
      });
      await amplitude.add(sessionReplayTracking).promise;
    }

    amplitude.init(this.apiKey, {
      defaultTracking: false,
      minIdLength: 1,
    });
  }

  public setUser(user: ApiMeEmployee) {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set("Email", user.email);
    identifyEvent.set("Name", `${user.first_name} ${user.last_name}`);
    amplitude.identify(identifyEvent);
    amplitude.setUserId(user.id.toString());
    amplitude.setGroup("Organization", user.organization_id.toString());
    amplitude.setGroup("Organization Title", user.organization.title);
  }

  public trackEvent(
    eventName: string,
    properties: Record<string, string | number | boolean | undefined> = {}
  ) {
    amplitude.track(eventName, {
      ...properties,
      [analyticProperties.url]: window.location.href,
    });
  }
}

const analyticsService = new AnalyticsService(import.meta.env.VITE_AMPLITUDE_KEY);
export default analyticsService;
