import Box from "../../../../../ui/box/Box.tsx";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import DropdownMenu from "../../../../../ui/dropdown-menu/DropdownMenu.tsx";
import Text from "../../../../../ui/typography/Text.tsx";
import { ListItem } from "../../../../../components/list-item/ListItem.tsx";
import { EditRoleSchema } from "../role-edit.types.ts";
import useRoleEditService, { FieldPermissionConfigItem } from "../role-edit-service.ts";
import { SelectOption } from "../../../../../ui/select/SelectOption.tsx";

export interface RoleEditEmployeePermissionFieldProps {
  item: FieldPermissionConfigItem;
  register: UseFormRegister<EditRoleSchema>;
  control: Control<EditRoleSchema>;
}

export function RoleEditPermissionField({
  item,
  register,
  control,
}: RoleEditEmployeePermissionFieldProps) {
  const { permissionTypesTexts } = useRoleEditService();

  return (
    <ListItem
      preTitle={item.title}
      valueSlot={
        <Box className={"w-1/2"}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <DropdownMenu
                trigger={
                  <Text
                    className={"underline text-dark/95 cursor-pointer hover:no-underline"}
                    tabIndex={0}
                  >
                    {permissionTypesTexts[value]}
                  </Text>
                }
              >
                {item.availableOptions.map((option, optionIndex) => (
                  <SelectOption
                    isMulti={false}
                    key={`employee-field-permission-option-${optionIndex}`}
                    handleSelect={() => {
                      onChange(option);
                    }}
                    showCaption={true}
                    item={{
                      item: option,
                      id: option,
                      title: permissionTypesTexts[option],
                      caption: item.availableOptionsDescription?.[option],
                      selected: option == value,
                    }}
                    {...register(item.field)}
                  />
                ))}
              </DropdownMenu>
            )}
            control={control}
            name={item.field}
          />
        </Box>
      }
    ></ListItem>
  );
}
