export enum ApiCalendarProviders {
  system = "system",
  google_calendar = "google_calendar",
}

export interface ApiCalendar {
  id: number;
  title: string;
  timezone: string;
  is_default: boolean;
  provider: ApiCalendarProviders;
}
