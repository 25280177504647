import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import { EmployeeInlineCardProps } from "./employee-inline-card.types.ts";
import { EmployeeAvatar } from "../employee-avatar/EmployeeAvatar.tsx";
import { Link } from "react-router-dom";

export const EmployeeInlineCard = ({ employee, primary }: EmployeeInlineCardProps) => {
  const { renderEmployeeName } = useFormatter();
  return (
    <Link to={`/employees/${employee.id}`}>
      <Stack direction={"horizontal"} className={"items-center"} gap={"sm"}>
        <EmployeeAvatar size={"md"} employee={employee} />
        <Text className={primary ? "text-title" : undefined}>{renderEmployeeName(employee)}</Text>
      </Stack>
    </Link>
  );
};
