import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import Modal from "../../../../ui/modal/modal.tsx";
import { ApiEmployee } from "../../../../types/employees/employee.types.ts";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { Textarea } from "../../../../ui/textarea/Textarea.tsx";
import { useEmployeeDismissalReasons } from "../../../../queries/employees/use-employee-dismissal-reason.query.ts";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import Select from "../../../../ui/select/Select.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { useEmployeePositionSave } from "../../../../queries/employees/use-employee-positions.query.ts";
import {
  ApiEmployeeAssignPositionPayload,
  ApiEmployeePosition,
} from "../../../../types/employees/employee-position.types.ts";

export interface EmployeeDismissalModalProps extends ModalProps {
  employee: ApiEmployee;
  employeePosition: ApiEmployeePosition;
}

export function EmployeeDismissalModal({
  employee,
  employeePosition,
  ...props
}: EmployeeDismissalModalProps) {
  const { t } = useTranslation();
  const { id } = { ...props };
  const { close } = useModals();
  const { data: reasons } = useEmployeeDismissalReasons();
  const EmployeeDismissalSchemaObject = yup.object({
    dismissal_reason_id: yup.number().required(i18n.t("errors:field_required")),
    date_effective_from: yup.date().required(i18n.t("errors:field_required")),
    comment: yup.string(),
  });

  interface EmployeeDismissalSchema extends yup.InferType<typeof EmployeeDismissalSchemaObject> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EmployeeDismissalSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EmployeeDismissalSchemaObject),
    defaultValues: {
      dismissal_reason_id: employeePosition?.dismissal_reason?.id,
      date_effective_from: employeePosition?.date_effective_from,
      comment: employeePosition?.comment,
    },
  });

  const employeePositionSave = useEmployeePositionSave(control);

  const onSubmit = handleSubmit(async (data) => {
    const payload: ApiEmployeeAssignPositionPayload = {
      dismissal_reason_id: data.dismissal_reason_id,
      date_effective_from: data.date_effective_from,
      comment: data.comment ?? "",
    };

    try {
      await employeePositionSave.mutateAsync({
        employeeId: employee.id,
        positionId: employeePosition.id,
        payload: payload,
      });
      if (employeePosition.id == 0) {
        analyticsService.trackEvent(analyticEvents.employees.dismissed, {
          [analyticProperties.id]: employee.id,
        });
      }
      close(id);
    } catch {
      return;
    }
  });

  return (
    <Modal
      {...props}
      withCloser={true}
      title={t("core:termination")}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button
            type={"submit"}
            size={"lg"}
            onClick={onSubmit}
            isLoading={employeePositionSave.isPending}
          >
            {t("common:save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Select
              options={reasons || []}
              required={true}
              emptyTitle={t("core:reason")}
              searchable
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          name={"dismissal_reason_id"}
        />
        <Controller
          render={({ field: { value, onChange } }) => (
            <Datepicker
              value={value}
              onChange={onChange}
              label={t("core:termination_date")}
              required={true}
              error={errors.date_effective_from?.message}
            />
          )}
          control={control}
          name={"date_effective_from"}
        />
        <Textarea label={t("common:comment")} {...register("comment")} />
      </form>
    </Modal>
  );
}
