import React, { ComponentProps } from "react";
import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";

export interface LinkToPropsSizes extends Pick<ThemeSizes, "sm" | "md" | "lg"> {
  [key: string]: string;
}

export interface LinkToProps extends ComponentProps<"a"> {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
  variant?: keyof LinkVariant;
}

export interface LinkButtonToProps extends ComponentProps<"button"> {
  children: React.ReactNode;
  isLoading?: boolean;
  // onClick?: () => void;
  size?: keyof LinkToPropsSizes;
  variant?: keyof LinkVariant;
}

export interface UIKitLinkToTheme extends UIKitThemeBase {
  variants: {
    variant: {
      primary: string;
      secondary: string;
      plain: string;
    };
    size: LinkToPropsSizes;
  };
}

export interface LinkVariant {
  primary: string;
  secondary: string;
  plain: string;
  // menu: string;
  // tertiary: string;
}

export const linkToTheme: UIKitLinkToTheme = {
  base: "inline underline-offset-2 cursor-pointer",
  variants: {
    variant: {
      primary: "underline",
      secondary: "hover:underline",
      plain: "",
    },
    size: {
      sm: "text-sm font-semibold",
      md: "text-base",
      lg: "text-lg",
    },
  },
};
