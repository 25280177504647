import {
  ApiApplicantDataProtection,
  DataProtectionObjectTypes,
  DataProtectionState,
  DataProtectionTypes,
} from "../../../../types/data-protection.types.ts";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { ReactNode } from "react";
import Text from "../../../../ui/typography/Text.tsx";
import { useTranslation } from "react-i18next";
import useDataProtectionHook from "../../../../hooks/use-data-protection.hook.ts";
import ListOption from "../../../../ui/list-option/ListOption.tsx";
import { Rounding } from "../../../../types/common.types.ts";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { dataProtectionKeys } from "../../../../queries/use-data-protection.query.ts";
import { useQueryClient } from "@tanstack/react-query";
import { apiDataProtection } from "../../../../api/data-protection.api.ts";
import { applicantsKeys } from "../../../../queries/recruit/use-applicants.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import useErrorHandle from "../../../../hooks/use-error-handle.hook.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import { DataProtectionIncreasePeriod } from "../../../settings/data-protection/index/widget/DataProtectionIncreasePeriod.tsx";

export interface ApplicantDataProtectionOptionProps {
  dataProtection: ApiApplicantDataProtection;
  applicantId: number;
}

export function ApplicantDataProtectionOption({
  dataProtection,
  applicantId,
}: ApplicantDataProtectionOptionProps) {
  const { t } = useTranslation();
  const { getDaysToDate } = useFormatter();
  const { getTitleByType } = useDataProtectionHook();
  const queryClient = useQueryClient();
  const { handleErrors } = useErrorHandle();
  const { openModal } = useModals();

  const getIconForListByState = (state: DataProtectionState): JSX.Element => {
    switch (state) {
      case DataProtectionState.requested:
        return <FontAwesomeIcon icon="fa-light fa-clock-rotate-left text-warning" />;
      case DataProtectionState.given:
        return <FontAwesomeIcon icon="fa-light fa-circle-check text-positive" />;
      case DataProtectionState.expiring:
        return <FontAwesomeIcon icon="fa-light fa-circle-check text-warning" />;
      case DataProtectionState.expired:
        return <FontAwesomeIcon icon="fa-light fa-circle-exclamation text-danger" />;
      case DataProtectionState.declined:
        return <FontAwesomeIcon icon="fa-light fa-circle-exclamation text-danger" />;
      case DataProtectionState.empty:
      default:
        return <FontAwesomeIcon icon="fa-light fa-paper-plane" />;
    }
  };

  const getStatusForListByState = (data: ApiApplicantDataProtection): string => {
    switch (data.state) {
      case DataProtectionState.requested:
        return t("data_protection:status_request_requested", {
          count_days: t("plurals:plural_day", { count: getDaysToDate(data.datetime_state_start) }),
        });
      case DataProtectionState.given:
        return t("data_protection:status_request_given", {
          count_days: t("plurals:plural_day", {
            count: getDaysToDate(data.datetime_state_end, Rounding.up),
          }),
        });
      case DataProtectionState.expiring:
        return t("data_protection:status_request_given", {
          count_days: t("plurals:plural_day", {
            count: getDaysToDate(data.datetime_state_end, Rounding.up),
          }),
        });
      case DataProtectionState.expired:
        return t("data_protection:status_request_expired", {
          count_days: t("plurals:plural_day", { count: getDaysToDate(data.datetime_state_start) }),
        });
      case DataProtectionState.declined:
        return t("data_protection:status_request_declined", {
          count_days: t("plurals:plural_day", { count: getDaysToDate(data.datetime_state_start) }),
        });
      case DataProtectionState.empty:
      default:
        return t("data_protection:status_request_not_sent");
    }
  };

  const getTitleByProviderAndState = (
    state: DataProtectionState,
    type: DataProtectionTypes
  ): ReactNode => {
    switch (state) {
      case DataProtectionState.requested:
        return (
          <Text className={"text-warning"}>
            {t("data_protection:title_request_requested", { type_title: getTitleByType(type) })}
          </Text>
        );
      case DataProtectionState.given:
        return (
          <Text className={"text-positive"}>
            {t("data_protection:title_request_given", { type_title: getTitleByType(type) })}
          </Text>
        );
      case DataProtectionState.expiring:
        return (
          <Text className={"text-warning"}>
            {t("data_protection:title_request_expiring", { type_title: getTitleByType(type) })}
          </Text>
        );
      case DataProtectionState.expired:
        return (
          <Text className={"text-danger"}>
            {t("data_protection:title_request_expired", { type_title: getTitleByType(type) })}
          </Text>
        );
      case DataProtectionState.declined:
        return (
          <Text className={"text-danger"}>
            {t("data_protection:title_request_declined", { type_title: getTitleByType(type) })}
          </Text>
        );
      case DataProtectionState.empty:
      default:
        return (
          <Text>
            {t("data_protection:title_request_empty", { type_title: getTitleByType(type) })}
          </Text>
        );
    }
  };

  const onClickDataProtection = async (type: DataProtectionTypes) => {
    const { success, error } = await apiDataProtection.consentRequest({
      object_id: applicantId,
      object_type: DataProtectionObjectTypes.applicant,
      data_protection_type: type,
    });

    if (success) {
      if (DataProtectionObjectTypes.applicant === "applicant") {
        queryClient.invalidateQueries({
          queryKey: applicantsKeys.detail(applicantId).queryKey,
        });
      }

      queryClient.invalidateQueries({
        queryKey: dataProtectionKeys.searchRequests._def,
      });

      analyticsService.trackEvent(analyticEvents.dataProtection.requestSent, {
        [analyticProperties.id]: applicantId,
      });
    } else handleErrors(error);
  };

  const increasePeriod = () => {
    openModal(DataProtectionIncreasePeriod, {
      objectId: applicantId,
      type: dataProtection.type,
    });
  };

  return (
    <>
      <ListOption
        onClick={() => {
          onClickDataProtection(dataProtection.type);
        }}
        key={`protection-${dataProtection.type}`}
        leftIcon={getIconForListByState(dataProtection.state)}
        titleSlot={getTitleByProviderAndState(dataProtection.state, dataProtection.type)}
        caption={getStatusForListByState(dataProtection)}
      />
      {(dataProtection.state === DataProtectionState.given ||
        dataProtection.state === DataProtectionState.expiring) && (
        <ListOption
          onClick={() => {
            increasePeriod();
          }}
          key={`protection-manual-${dataProtection.type}`}
          leftIcon={<FontAwesomeIcon icon="fa-light fa-gear" />}
          title={t("data_protection:increase_manually_title")}
          caption={t("data_protection:increase_manually_description")}
        />
      )}
    </>
  );
}
