import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException } from "../../types/api.types.ts";
import { apiIntegrations } from "../../api/integrations/integration.api.ts";
import { ApiIntegrationGoogleCalendarSave } from "../../types/integrations/integration.types.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";

const organizationIntegrationKeys = createQueryKeys("organizationIntegrations", {
  integrations: null,
});

export const useIntegrations = () => {
  return useQuery({
    ...organizationIntegrationKeys.integrations,
    queryFn: async () => {
      const result = await apiIntegrations.getInstalledForOrganization();

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
    staleTime: 60 * 1000,
  });
};

export const useGoogleCalendarManage = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async (payload: ApiIntegrationGoogleCalendarSave) => {
      const result = await apiIntegrations.manageGoogleCalendar(payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: organizationIntegrationKeys.integrations.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};
