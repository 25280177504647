import { twMerge } from "tailwind-merge";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import { ToastProps } from "./toast.types.ts";
import { useTheme } from "../themes/provider.tsx";
import ButtonIcon from "../button/ButtonIcon.tsx";

export default function Toast({
  title,
  type = "info",
  visible = false,
  description,
  onClick,
}: ToastProps) {
  const visibleClassName = visible ? "animate-enter" : "animate-leave";
  const theme = useTheme("toast");
  return (
    <>
      <div
        className={twMerge(
          theme.base,
          theme.variants.variant[type],
          visibleClassName,
          "pointer-events-auto"
        )}
      >
        <div className={theme.contentContainer}>
          <p className={theme.content.title}>{title}</p>
          {description && <p className={theme.content.description}>{description}</p>}
        </div>
        <div className={theme.buttonContainer}>
          <ButtonIcon
            icon={<FontAwesomeIcon icon="fa-light fa-xmark" />}
            variant="primary"
            onClick={onClick}
          />
        </div>
      </div>
    </>
  );
}
