import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { candidatesKeys } from "./use-candidates.query.ts";
import { applicantsKeys } from "./use-applicants.query.ts";
import { jobsKeys } from "./use-jobs.query.ts";
import { apiCandidateActions } from "../../api/candidate-action.api.ts";
import { Control, FieldValues } from "react-hook-form";
import { ApiActionCreateByFilterPayload, ApiActionPayload } from "../../types/action.types.ts";

export const candidateActionsKeys = createQueryKeys("candidateActions", {
  list: (id: number) => ({
    queryKey: [id],
    queryFn: async () => {
      const result = await apiCandidateActions.getAll(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useCandidateActions = (id: number, enabled: boolean = true) => {
  return useQuery({
    ...candidateActionsKeys.list(id),
    enabled: enabled,
  });
};

export const useCandidateActionCreate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: ApiActionPayload }) => {
      const result = await apiCandidateActions.create(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: (_, variables) => {
      // Обновляем все экшены
      queryClient.invalidateQueries({
        queryKey: candidateActionsKeys.list(variables.id).queryKey,
      });
      // Обновляем кандидатов текущего апликанта
      queryClient.invalidateQueries({
        queryKey: candidatesKeys.listByApplicantId._def,
      });
      // Обновляем список апликантов
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.searchInfinite._def,
      });
      // Обновляем список вакансий
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.details._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useCandidateActionUpdate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({
      id,
      actionId,
      payload,
    }: {
      id: number;
      actionId: number;
      payload: ApiActionPayload;
    }) => {
      const result = await apiCandidateActions.update(id, actionId, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: (_, variables) => {
      // Обновляем все экшены
      queryClient.invalidateQueries({
        queryKey: candidateActionsKeys.list(variables.id).queryKey,
      });
      // Обновляем кандидатов текущего апликанта
      queryClient.invalidateQueries({
        queryKey: candidatesKeys.listByApplicantId._def,
      });
      // Обновляем список апликантов
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.searchInfinite._def,
      });
      // Обновляем список вакансий
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.details._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

interface ActionDeleteParams {
  applicantId: number;
  candidateId: number;
  actionId: number;
}

export const useCandidateActionDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ candidateId, actionId }: ActionDeleteParams) => {
      const result = await apiCandidateActions.delete(candidateId, actionId);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      // Обновляем все экшены
      queryClient.invalidateQueries({
        queryKey: candidateActionsKeys.list(variables.candidateId).queryKey,
      });
      // Обновляем кандидатов текущего апликанта
      queryClient.invalidateQueries({
        queryKey: candidatesKeys.listByApplicantId(variables.applicantId).queryKey,
      });
      // Обновляем список апликантов
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.searchInfinite._def,
      });
      // Обновляем список вакансий
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.details._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useCandidateActionUpdateByFilter = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async (payload: ApiActionCreateByFilterPayload) => {
      const result = await apiCandidateActions.createByFilter(payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      // Обновляем все экшены
      queryClient.invalidateQueries({
        queryKey: candidateActionsKeys.list._def,
      });
      // Обновляем кандидатов текущего апликанта
      queryClient.invalidateQueries({
        queryKey: candidatesKeys.listByApplicantId._def,
      });
      // Обновляем список апликантов
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.searchInfinite._def,
      });
      // Обновляем список вакансий
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.details._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};
