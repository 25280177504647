import Button from "../../ui/button/Button.tsx";
import Stack from "../../ui/stack/Stack.tsx";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import analyticsService, { analyticEvents } from "../../services/analytics-service.ts";
import Text from "../../ui/typography/Text.tsx";
import { Input } from "../../ui/input/Input.tsx";
import LinkTo from "../../ui/link/LinkTo.tsx";
import useErrorHandle, { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import { sendTokenToExtension } from "../../helpers/send-token-to-extension.ts";
import { useAuthStore } from "../../stores/employee.store.ts";
import { Title } from "../../components/title/Title.tsx";
import { FullScreenCard } from "./components/FullScreenCard.tsx";
import { apiEmployees } from "../../api";
import { useState } from "react";
import { useEmployeeMeInvalidate } from "../../queries/employees/use-employees.query.ts";
import Box from "../../ui/box/Box.tsx";
import { ListItem } from "../../components/list-item/ListItem.tsx";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToken } = useAuthStore();
  const { handleErrors } = useErrorHandle();

  const loginSchema = yup.object({
    email: yup
      .string()
      .email(t("errors:field_email_requited"))
      .required(t("errors:field_required")),
    password: yup.string().required(t("errors:password_required")),
  });

  interface LoginSchema extends yup.InferType<typeof loginSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginSchema>({
    mode: "onSubmit",
    resolver: yupResolver(loginSchema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const employeeMeInvalidate = useEmployeeMeInvalidate();

  const onSubmit = handleSubmit(async (loginData) => {
    setIsLoading(true);
    const { data, error } = await apiEmployees.login(loginData.email, loginData.password);
    setIsLoading(false);

    if (data) {
      setToken(data.access_token);
      sendTokenToExtension(data.access_token);
      employeeMeInvalidate();
      analyticsService.trackEvent(analyticEvents.loggedIn);
      navigate("/");
    } else {
      handleErrors(error, control);
    }
  });

  return (
    <>
      <FullScreenCard>
        <Stack gap={"sm"} className={"w-full sm:max-w-md "}>
          <Box className="w-full p-6 lg:p-8 bg-light rounded-md shadow-md">
            <Stack gap={"2xl"}>
              <a
                href="https://skailer.com"
                className="flex items-center mb-6 text-2xl font-semibold"
              >
                <img className="w-8 h-8 mr-2  " src="/skailer-dark-solid.svg" alt="logo" />
                <Text size="3xl">skailer</Text>
              </a>
              <Stack gap={"lg"}>
                <Title
                  header={t("core:login_to_platform")}
                  size={"lg"}
                  caption={`${t("core:login_to_platform_subtitle")} 🌼`}
                />
                <form className="" onSubmit={onSubmit}>
                  <Input
                    label={t("core:email")}
                    type="email"
                    {...register("email")}
                    error={errors.email?.message}
                  />

                  <Input
                    label={t("common:password")}
                    type="password"
                    {...register("password")}
                    placeholder="••••••••"
                    error={errors.password?.message}
                  />
                </form>
                <ServerErrorField errors={errors} />
                <Button
                  type={"submit"}
                  size="lg"
                  onClick={onSubmit}
                  className="w-full"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {t("common:sign_in")}
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Stack className={"px-6 lg:px-8"}>
            <ListItem
              title={t("core:forgot_password")}
              valueSlot={<LinkTo to={"/forgot-password"}>{t("core:restore_password")}</LinkTo>}
            />
            <ListItem
              title={t("core:dont_have_an_account")}
              valueSlot={<LinkTo to={"/signup"}>{t("common:sign_up")}</LinkTo>}
            />
          </Stack>
        </Stack>
      </FullScreenCard>
    </>
  );
}
