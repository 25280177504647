import { useAuthStore, useEmployeeStore } from "../../../stores/employee.store.ts";
import { sendTokenToExtension } from "../../../helpers/send-token-to-extension.ts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "../../../ui/box/Box.tsx";
import Avatar from "../../../ui/avatar/Avatar.tsx";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import { useState } from "react";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
} from "@floating-ui/react";
import Stack from "../../../ui/stack/Stack.tsx";
import { Title } from "../../title/Title.tsx";
import Button from "../../../ui/button/Button.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { usePermissionCheck } from "../../../helpers/use-permission-check.ts";
import ButtonLink from "../../../ui/button/ButtonLink.tsx";

export const SidebarUserMenu = () => {
  const { employee } = useEmployeeStore();
  const { setToken } = useAuthStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { renderEmployeeName } = useFormatter();
  const { hasPermissionsToViewSettings } = usePermissionCheck();

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "right-end",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([click, focus, dismiss]);

  const logout = () => {
    setToken(null);
    sendTokenToExtension(null);
    navigate("/login");
  };

  if (!employee) return null;

  return (
    <>
      <Box className={"w-8 h-8 cursor-pointer"} ref={refs.setReference} {...getReferenceProps()}>
        <Avatar size={"md"} url={employee.avatar_url} acronym={renderEmployeeName(employee)} />
      </Box>
      {isOpen && (
        <FloatingPortal>
          <Box
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className={"p-smd z-popover"}
          >
            <Stack gap={"smd"}>
              <Stack
                items={"center"}
                className={"bg-light rounded-lg shadow-sm w-48 p-4"}
                gap={"sm"}
              >
                <Avatar
                  size={"xl"}
                  url={employee.avatar_url}
                  acronym={renderEmployeeName(employee)}
                />
                <Title header={renderEmployeeName(employee)} />
                <ButtonLink
                  variant={"secondary"}
                  size={"sm"}
                  href={"/account-settings"}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t("core:manage_profile")}
                </ButtonLink>
              </Stack>
              <Stack
                items={"center"}
                className={"bg-light rounded-lg shadow-sm w-48 p-2"}
                gap={"sm"}
              >
                <ButtonLink
                  onClick={() => {
                    analyticsService.trackEvent(analyticEvents.pageAction, {
                      [analyticProperties.actionType]: "Extension Clicked",
                      [analyticProperties.source]: "User Menu",
                    });
                    setIsOpen(false);
                  }}
                  blank
                  leftIcon={<FontAwesomeIcon icon={"fa-brands fa-chrome"} />}
                  variant={"menu"}
                  href={
                    "https://chromewebstore.google.com/detail/skailer-sourcing-extensio/iifbgblakgpljhhnejbmfoajbbfoiocl"
                  }
                >
                  {t("ats:chrome_extension")}
                </ButtonLink>
                {hasPermissionsToViewSettings && (
                  <ButtonLink
                    href={"/settings"}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    variant={"menu"}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-gear"} />}
                  >
                    {t("common:settings")}
                  </ButtonLink>
                )}
                <Button
                  onClick={() => {
                    setIsOpen(false);
                    logout();
                  }}
                  variant={"menu"}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-right-from-bracket"} />}
                >
                  {t("common:logout")}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </FloatingPortal>
      )}
    </>
  );
};
