import { ApiDepartmentShort } from "../department.types.ts";
import { ApiEmployeeShort } from "../employees/employee.types.ts";
import { SelectItem } from "../../ui/select/select.types.ts";
import i18n from "../../i18n.ts";
import { ApiFilterBase } from "../filter.types.ts";
import { ApiDivisionShort } from "../division.types.ts";
import { ApiLevel, ApiPositionShort } from "../position.types.ts";
import { ApiPipeline } from "./pipeline.types.ts";
import { ApiCurrency } from "../currency.types.ts";

export enum JobStatus {
  draft = "draft",
  published = "published",
  // closed = "closed",
  // held = "held",
  // cancelled = "cancelled",
  archived = "archived",
}

export interface ApiJobShort {
  id: number;
  title: string;
  status: JobStatus;
  description: string;
  emoji: string;

  candidates_count: number;
}

export interface ApiJob extends ApiJobShort {
  department: ApiDepartmentShort;
  division?: ApiDivisionShort;
  stakeholders: ApiEmployeeShort[];
  recruiters: ApiEmployeeShort[];
  position?: ApiPositionShort;
  pipeline: ApiPipeline;
  level?: ApiLevel;
  salary_min?: number;
  salary_max?: number;
  currency?: ApiCurrency;
}

export interface JobStatusItem extends SelectItem {}

export const JobStatusItems = () => {
  return [
    {
      id: JobStatus.draft,
      title: i18n.t("ats:job_status_draft"),
    },
    {
      id: JobStatus.published,
      title: i18n.t("ats:job_status_published"),
    },
    {
      id: JobStatus.archived,
      title: i18n.t("ats:job_status_archived"),
    },
  ] as JobStatusItem[];
};

export interface ApiJobSavePayload {
  title: string;
  status: JobStatus;
  description: string;
  division_id?: number;
  department_id: number;
  salary_min?: number;
  salary_max?: number;
  currency_code?: string;
  position_id?: number;
  level_id?: number;
  recruiter_ids: number[];
  stakeholder_ids: number[];
  pipeline_id: number;
  emoji: string;
}

export interface ApiJobsFilter extends ApiFilterBase {
  text?: string;
  status_list: JobStatus[];
  department_ids: number[];
  recruiter_ids: number[];
  my_jobs?: boolean;
}

export const ApiJobsFilterDefault: ApiJobsFilter = {
  page_number: 1,
  status_list: [],
  department_ids: [],
  recruiter_ids: [],
};

export interface JobEdit {
  id: number;
  title: string;
  emoji: string | null;
  status: JobStatus;
  description: string;
  department?: ApiDepartmentShort;
  division?: ApiDivisionShort;
  stakeholders: ApiEmployeeShort[];
  recruiters: ApiEmployeeShort[];
  pipeline?: ApiPipeline;
  level?: ApiLevel;
  salary_min?: number;
  salary_max?: number;
  currency?: ApiCurrency;
  position?: ApiPositionShort;
}

export const JobEmojiDefault = "🧑‍💻";
export const JobEmojiMapToBackgrounds: Map<string, string> = new Map([
  ["🍀", "/backgrounds/leaps.jpg"],
  ["🌻", "/backgrounds/sunflowers.jpg"],
  ["🌹", "/backgrounds/roses.jpg"],
  ["🌷", "/backgrounds/tulips.jpg"],
]);

export const getJobEmoji = (job: ApiJobShort) => {
  return job.emoji || JobEmojiDefault;
};

export const getRandomDefaultEmoji = (): string => {
  const keys = Array.from(JobEmojiMapToBackgrounds.keys());

  if (keys.length === 0) return JobEmojiDefault; // Возвращаем пустую строку, если Map пуста

  const randomIndex = Math.floor(Math.random() * keys.length);

  return keys[randomIndex];
};

export const getBackgroundByEmoji = (emoji: string): string => {
  return JobEmojiMapToBackgrounds.get(emoji) ?? "/backgrounds/clouds.jpeg";
};

export const getJobTitleFull = (job: ApiJobShort) => {
  return `${job.emoji || JobEmojiDefault} ${job.title}`;
};

export const ApiJobEmpty: JobEdit = {
  id: 0,
  title: "",
  status: JobStatus.published,
  description: "",
  department: undefined,
  stakeholders: [],
  recruiters: [],
  pipeline: undefined,
  emoji: getRandomDefaultEmoji(),
};
