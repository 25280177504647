import { AppTheme } from "../theme.types.ts";
import { AppThemeConfig } from "../theme.config.types.ts";
import { sidebarTheme } from "./sidebar.ts";

export const DefaultAppTheme: AppTheme = {
  body: {
    base: "text-default text-base bg-gradient-to-br from-[#DBDAC3] to-[#868D93]",
  },
  layout: {
    base: "flex relative",
    aside: "w-14 h-full flex-shrink-0 fixed transition-all duration-75",
    main: "w-full ml-14 overflow-y-auto transition-all duration-75",
    variant: {
      collapsed: {
        aside: "w-28",
        main: "ml-28",
      },
      withSidebar: {
        aside: "w-72",
        main: "ml-72",
      },
    },
  },
  sidebar: sidebarTheme,
  page: {
    base: "min-h-full flex flex-col bg-light relative",
    header: "flex-shrink-0 min-h-16 px-6 py-4",
    content: "px-6 pb-6",
  },
};
export const DefaultAppThemeConfig: AppThemeConfig = {
  name: "default",
  colorBrandDark: "220deg 10% 12%",
  // colorBrandLight: "210deg 12% 97%",
};
