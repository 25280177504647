import i18n from "i18next";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";

type TranslationResources = {
  [language: string]: {
    [namespace: string]: { [key: string]: any };
  };
};

export function loadTranslations(): TranslationResources {
  const resources: TranslationResources = {};
  const modules = import.meta.glob("./locales/**/*.json", {
    query: "?v=3",
    eager: true,
    import: "default",
  });

  for (const path in modules) {
    const jsonContent = modules[path];

    const pathParts = path.split("/");
    const language = pathParts[2];
    const namespace = pathParts[3].replace(".json", "");

    if (!resources[language]) {
      resources[language] = { translation: {} };
    }
    if (!resources[language][namespace]) {
      resources[language][namespace] = {};
    }

    Object.assign(resources[language][namespace], jsonContent);
  }

  return resources;
}

export function initializeI18n() {
  const resources = loadTranslations();

  i18n
    .use(ICU) // special formats for plural and etc
    .use(initReactI18next)
    .init({
      resources,
      debug: false,
      lng: "en", // default language
      keySeparator: false, // we do not use keys in form messages.welcome
      // ns: ["ats", "common", "app"],
      nsSeparator: ":",
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
}

initializeI18n();
export default i18n;
