import Button from "../../ui/button/Button.tsx";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { apiEmployees } from "../../api";
import { useTranslation } from "react-i18next";
import { Input } from "../../ui/input/Input.tsx";
import useToasts from "../../hooks/use-toasts.hook.tsx";
import useErrorHandle, { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import { FullScreenCard } from "./components/FullScreenCard.tsx";
import { Title } from "../../components/title/Title.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import LinkTo from "../../ui/link/LinkTo.tsx";
import Box from "../../ui/box/Box.tsx";
import Text from "../../ui/typography/Text.tsx";
import { ListItem } from "../../components/list-item/ListItem.tsx";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const { handleErrors } = useErrorHandle();

  const ForgotPasswordSchemaObject = yup.object({
    email: yup
      .string()
      .email(t("errors:field_email_requited"))
      .required(t("errors:field_required")),
  });

  interface ForgotPasswordSchema extends yup.InferType<typeof ForgotPasswordSchemaObject> {
    // using interface instead of type generally gives nicer editor feedback
  }

  const { showInfo } = useToasts();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordSchema>({
    mode: "onSubmit",
    resolver: yupResolver(ForgotPasswordSchemaObject),
  });

  const onSubmit = handleSubmit(async (loginData) => {
    setLoading(true);
    const { data, error } = await apiEmployees.forgotPassword(loginData.email);
    if (data) {
      showInfo(t("core:email_with_password_reset_was_sent"));
    } else {
      handleErrors(error, control);
    }
    setLoading(false);
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <FullScreenCard>
      <Stack gap={"sm"} className={"w-full sm:max-w-md "}>
        <Box className="w-full p-6 lg:p-8 bg-light rounded-md shadow-md">
          <Stack gap={"2xl"}>
            <a href="https://skailer.com" className="flex items-center mb-6 text-2xl font-semibold">
              <img className="w-8 h-8 mr-2  " src="/skailer-dark-solid.svg" alt="logo" />
              <Text size="3xl">skailer</Text>
            </a>
            <Stack gap={"lg"}>
              <Title header={t("core:restore_password")} size={"lg"} />
              <form className="" onSubmit={onSubmit}>
                <Input
                  label={t("core:email")}
                  type="email"
                  {...register("email")}
                  error={errors.email?.message}
                />
              </form>
              <ServerErrorField errors={errors} />
              <Button
                type={"submit"}
                size="lg"
                onClick={onSubmit}
                className="w-full"
                isLoading={isLoading}
                disabled={isLoading}
              >
                {t("common:restore")}
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Stack className={"px-6 lg:px-8"}>
          <ListItem
            title={t("core:already_have_an_account")}
            valueSlot={<LinkTo to={"/login"}>{t("common:login")}</LinkTo>}
          />
        </Stack>
      </Stack>
    </FullScreenCard>
  );
}
